import {TaskData} from './task'
import {WardInfo} from './ward'
import {EmployeeInfo} from './employees'
import { Socket } from 'socket.io-client'

export enum AlertType {
	assigned, // Được giao việc
	pending,
	approved, // Đã duyệt hoàn thành
	expired,
	oneDay, // Còn 1 ngày
	refused, // Đã duyệt chưa hoàn thành
	success,
	colleagueAssigned, // Đồng nghiệp được giao việc
	threeDays, // Còn 3 ngày
	receiving, // Được nhận
}

const NT = AlertType

export const AlertTypeGroup = {
	status: [NT.success, NT.pending, NT.expired],
	decision: [NT.approved, NT.refused],
	task: [NT.assigned, NT.colleagueAssigned],
	notice: [NT.threeDays, NT.oneDay],
	custom: [NT.receiving],
}

// quantity and id list of unseen alert and notification
export interface IUnseenAlert {
	total_unseen_alert_number: number
	total_unseen_alert_seen_id_list: number[]
}

export interface IUnseenNotification {
	total_unseen_notification_number: number
	total_unseen_notification_id_list: number[]
}

// used in NotificationProvider component
export interface INotificationContext extends IUnseenAlert, IUnseenNotification {
	// total_unseen_alert_number: number
	// total_unseen_notification_number: number
	socket: Socket | null
	refresh: () => void
}

export interface AlertEmployee {
	alert_employee_id: number
	created_at: string
	updated_at: string
	employee: EmployeeInfo
}

export interface AlertData {
	alert_id: number
	state: number
	updated_at: string
	created_at: string
	ward: WardInfo
	work: TaskData
	alert_employee: AlertEmployee[]
}

export interface IFile {
	notification_link_id: number
	notification_id: number
	link_content: string
	name: string
	size: number
	created_at: string
	updated_at: string
}

interface NotificationEmployee {
	notification_employee_id: number
	notification_id: number
	employee: EmployeeInfo
	updated_at: string
	created_at: string
}

export interface NotificationData {
	notification_id: 1
	notification_link: IFile[]
	created_at: string
	updated_at: string
	notification_employee: NotificationEmployee[]
	content: string
	header: string
	creator: EmployeeInfo
	ward: WardInfo
}

export interface NotificationHistoryData {
	notification_id: 1
	notification_link: IFile[]
	notification_employee: NotificationEmployee[]
	created_at: string
	updated_at: string
	content: string
	header: string
	// creator: EmployeeInfo
	ward: WardInfo
}
