import React, {useState, useEffect} from 'react'
import './App.css'
import NotificationPage_CB from './pages/NotificationPage_CB'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import TaskManagementPage_CB from './pages/TaskManagement_CB'
import TaskAssignManagement from './pages/TaskAssignManagementPage'
import EmployeeManagement from './pages/EmployeesManagement'
import ManagerNotificationPage from './pages/ManagerNotificationPage'
import AccountInfoPage from './pages/AccountInfoPage'
import DefaultLayout from './layouts/DefaultLayout'
import LoginPage from './pages/Login'
import {RequireAuth} from './components/Auth/RequireAuth'
import {useAuth} from './components/Auth/auth'
import {ToastContainer} from 'react-toastify'
import {UnseenNotificationProvider} from './components/UnseenNotificationProvider'
import 'react-toastify/dist/ReactToastify.css'
import 'react-quill/dist/quill.snow.css'
import './styles/global.scss'
import TestPage from './pages/TestPage'

function App() {
	const auth = useAuth()

	const checkIsCanBo = () => {
		return auth.user.level === 'Staff'
	}

	const checkIsManager = () => {
		return auth.user.level === 'Manager'
	}

	return (
		<BrowserRouter>
			<RequireAuth>
				<UnseenNotificationProvider>
					<DefaultLayout isCanBo={checkIsCanBo()}>
						<Routes>
							<Route
								path='/'
								element={
									auth.user.level === 'Staff' ? (
										<Navigate to={'/task_manage'} />
									) : (
										<Navigate to={'/task_assign'} />
									)
								}
							/>
							{auth.user.level !== 'Staff' && (
								<Route
									path='/task_assign'
									element={
										<TaskAssignManagement
											level={auth.user.level || ''}
										/>
									}
								/>
							)}
							{auth.user.level !== 'Leader' && (
								<Route
									path='/task_manage'
									element={<TaskManagementPage_CB />}
								/>
							)}
							{auth.user.level !== 'Staff' && (
								<Route
									path='/employee_manage'
									element={<EmployeeManagement />}
								/>
							)}
							<Route
								path='/notification'
								element={
									checkIsCanBo() ? (
										<NotificationPage_CB />
									) : (
										<ManagerNotificationPage />
									)
								}
							/>
							<Route path='/profile' element={<AccountInfoPage />} />
							<Route path='/test' element={<TestPage />} />
						</Routes>
					</DefaultLayout>
				</UnseenNotificationProvider>
			</RequireAuth>
			<Routes>
				<Route path='/login' element={<LoginPage />} />
			</Routes>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
				theme='light'
			/>
		</BrowserRouter>
	)
}

export default App
