import style from '../../styles/login.module.scss'
import clsx from 'clsx'
import {useState, useEffect} from 'react'
import {InputLabel as IL, OutlinedInput as OI, Button as B, styled, colors} from '@mui/material'
import {toast} from 'react-toastify'
import {useAuth} from '../../components/Auth/auth'
import {useNavigate} from 'react-router'
import client from '../../services/axios'

interface LoginResponseData {
	access_token: string
	refresh_token: string
}

const OutlinedInput = styled(OI)`
	width: 100%;
`
const InputLabel = styled(IL)`
	color: black;
`
const Button = styled(B)`
	padding: 0.5rem 1rem;
	width: auto;
	min-width: 0px;
`

function LoginPage() {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const auth = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		const emailInput = document.querySelector('#email') as HTMLInputElement
		emailInput.focus()

		const hanleKeyboardInput = (e: KeyboardEvent) => {
			const key = e.key
			if (key === 'Enter') {
				e.preventDefault()
				const emailInput = document.querySelector('#email') as HTMLInputElement
				const passwordInput = document.querySelector(
					'#password'
				) as HTMLInputElement
				handleLogin(emailInput.value, passwordInput.value)
			}
		}

		document.addEventListener('keydown', hanleKeyboardInput)

		return () => document.removeEventListener('keydown', hanleKeyboardInput)
	}, [])

	useEffect(() => {
		if (auth.user.accessToken) {
			navigate('/task_manage')
		}
	}, [auth])

	const handleLogin = async (email: string, password: string) => {
		if (email.length === 0) {
			toast.error('Email không được để trống')
			const emailInput = document.querySelector('#email') as HTMLInputElement
			emailInput.focus()
			return
		}

		if (password.length === 0) {
			toast.error('Password không được để trống')
			const passwordInput = document.querySelector(
				'#password'
			) as HTMLInputElement
			passwordInput.focus()
			return
		}

		try {
			// TODO: Request Login
			// TODO: Toast succes
			const resLogin = await toast.promise(
				client.post('/v1/employee/login', {email, password}),
				{
					pending: 'Đang đăng nhập',
					success: 'Đăng nhập thành công',
				}
			)

			const data = resLogin.data.data as LoginResponseData
			const accessToken = data.access_token
			const refreshToken = data.refresh_token

			localStorage.setItem('accessToken', accessToken as string)
			localStorage.setItem('refreshToken', refreshToken as string)

			const resUser = await client.get('/v1/employee/current')
			const wardId = resUser.data.data.ward.ward_id
			const employeeId = resUser.data.data.employee_id
			const level = resUser.data.data.level
			const name = resUser.data.data.name
			
			//INFO: Debug
			// console.log("Login - account level: ", level)

			setTimeout(() => {
				// TODO: Navigate to Home
				auth.login({accessToken, refreshToken, wardId, employeeId, level, name})
				
				if (level === 'Staff') {
					navigate('/task_manage')
				}
				else {
					navigate('/task_assign')
				}
			}, 3000)
		} catch (error: any) {
			// TODO: Toast error
			console.error('error', error)

			if (!error.response) {
				toast.error('Lỗi đăng nhập')
				return
			}

			if (error.response.status === 404) {
				toast.error('Tài khoản này không tồn tại')
			} else if (error.response.status === 401) {
				toast.error('Mật khẩu không hợp lệ')
			} else {
				toast.error('Lỗi đăng nhập')
			}
		}
	}

	return (
		<div
			style={{background: "#dde6ed"}}
			className={clsx(
				'w-full h-screen relative overflow-auto flex flex-col items-center',
				style['login-page']
			)}>
			<img className='pt-6 pb-2' src='Bieutrung_MTTQVN.svg' alt='Mặt trận tổ quốc Quận 5' style={{width: '100px', height: '100px'}}></img>
			<p className='font-bold text-xl pt-2 text-blue-900'>ỦY BAN MẶT TRẬN TỔ QUỐC VIỆT NAM</p>
			<p className='font-bold text-xl text-blue-900'>QUẬN 5 - THÀNH PHỐ HỒ CHÍ MINH</p>
			<p className='font-normal text-red-900 text-2xl pt-2 pb-6'>Phần mềm giao việc</p>
			<form
				className={clsx(
					'min-h-[350px] min-w-[500px] p-8 flex flex-col gap-8',
					style['login-form']
				)}>
				<h1 className='text-4xl font-bold self-center'>Đăng nhập</h1>
				<div className='flex flex-col gap-2 items-start'>
					<InputLabel htmlFor='email'>Email</InputLabel>
					<OutlinedInput
						id='email'
						required
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className='flex flex-col gap-2 items-start'>
					<InputLabel htmlFor='password'>Password</InputLabel>
					<OutlinedInput
						sx={{
							letterSpacing: '0.25rem',
						}}
						id='password'
						type='password'
						required
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<div className='self-center'>
					<Button
						variant='contained'
						color='primary'
						onClick={() => handleLogin(email, password)}>
						Xác nhận
					</Button>
				</div>
			</form>
		</div>
	)
}

export default LoginPage
