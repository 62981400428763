import React, {createContext, useContext, useState} from 'react'
import {User, UserContext} from '../../interfaces/user'
import client from '../../services/axios'

const AuthContext = createContext<UserContext>({} as UserContext)

export const AuthProvider = ({children}: any) => {
	const [user, setUser] = useState({} as User)

	const login = (user: User): void => {
		setUser(user)
	}
	const logout = async () => {
		setUser({} as User)

		if (user.employeeId) {
			await client.post('/v1/employee/current/logout')
		}
		
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
	}
	return (
		<AuthContext.Provider value={{user, login, logout}}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => {
	return useContext(AuthContext)
}
