import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow as TR,
	TableCell as Cell,
	Paper,
	Link,
	styled,
	Typography,
	CircularProgress,
	Stack,
	Pagination,
} from '@mui/material'
import dateFormatter from '../../helpers/formatters/dateFormatter'
import {TaskData, TaskStatus, TaskStatusStyle} from '../../interfaces/task'
import {IPagination} from '../../interfaces/pagination'
import SeeLinkModal from '../Modal/SeeLinkModal'
import ActionButton from '../Button/ActionButton.tsx'
import {useState} from 'react'
import ConfirmModal from '../Modal/ConfirmModal'
import SubmitLinkModal, {DataLink} from '../Modal/SubmitLinkModal'
import client from '../../services/axios'
import {toast} from 'react-toastify'
import { useAuth } from '../Auth/auth'
import { downloadFile } from '../../helpers/fileDownloader'

export interface MyTaskTableProps {
	data: IPagination<TaskData>
	canSubmit?: boolean
	canManage?: boolean
	loading?: boolean // should be required
	err?: boolean // should be required
	refreshPage: () => void
	onOpenSeeModal?: (task: TaskData) => void
	onChangePage?: (event: React.ChangeEvent<unknown>, value: number) => void
}

const TableRow = styled(TR)`
	width: 100%;
`

// Styling TableCell
const TableCell = styled(Cell)`
	font-size: 1.25rem;
`

const NowrapTypography = styled(Typography)`
	white-space: nowrap;
`
/**
 * @param canSubmit if true, can submit with TaskTable. Default false
 * @param canManage if true, can use action with TaskTable, such as delete. Default false
 */
function TaskTable({
	data,
	canSubmit = false,
	canManage = false,
	loading = false,
	err = false,
	refreshPage,
	onOpenSeeModal,
	onChangePage,
}: MyTaskTableProps) {
	const [open, setOpen] = useState(false) // cho check hoàn thành cviec
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false) // cho check xóa cviec
	const [currentTaskInModal, setCurrentTaskInModal] = useState<Number>(0)
	const auth = useAuth()

	const openDeleteTaskConfirmModal = (work_id: number): void => {
		setDeleteConfirmOpen(true)
		setCurrentTaskInModal(work_id)
	}

	const openConfirmPendingTask = (work_id: number): void => {
		setOpen(true)
		setCurrentTaskInModal(work_id)
	}

	// TODO: test this out
	const markTaskCompleted = async (value: boolean) => {
		try {
			const response = await toast.promise(
				client.put(
					`/v1/manager/work/mark_completed/${currentTaskInModal}`,
					{
						is_complete: value,
					},
					{
						params: {
							work_id: currentTaskInModal,
						},
					}
				),
				{
					pending: 'Đang thực hiện',
					success: 'Duyệt công việc thành công',
				}
			)
			refreshPage()
		} catch (error) {
			console.log(error)
			toast.error('Có lỗi xảy ra')
		}
	}
	// TODO: test this out
	const deleteTask = async () => {
		try {
			const response = await toast.promise(
				client.delete(`/v1/manager/work/${currentTaskInModal}`, {
					params: {
						work_id: currentTaskInModal,
					},
				}),
				{
					pending: 'Đang xóa',
					success: 'Xóa công việc thành công',
				}
			)
			// console.log(response.data)
			refreshPage()
		} catch (error) {
			toast.error('Có lỗi xảy ra')
		}
	}

	const handleSubmitLink = async (wordId: number, links: DataLink[]) => {
		console.log(wordId)
		try {
			const level = auth.user.level?.toLowerCase();
			const res = await toast.promise(
				client.post(`/v1/${level}/work_links`, {
					work_id: wordId,
					linkResultList: links.map((link) => link.value),
				}),
				{
					pending: 'Đang nộp link',
					success: 'Nộp link thành công',
					error: 'Nộp link thất bại',
				}
			)

			refreshPage()
		} catch (error) {
			// toast.error('Nộp link thất bại')
		}
	}

	// INFO: Debug
	// console.log(data.items[0].state)

	return (
		<TableContainer component={Paper} >
			<Table sx={{maxWidth: '100%'}}>
				<TableHead sx={{backgroundColor: '#e0e0e0'}}>
					<TableRow>
						<TableCell sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>
							Tên công việc
						</TableCell>
						<TableCell sx={{fontWeight: 'bold'}} align='center'>
							Hạn chót
						</TableCell>
						<TableCell sx={{fontWeight: 'bold', whiteSpace: 'nowrap',}}>
							Người đảm nhận
						</TableCell>
						<TableCell sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}}>Trạng thái</TableCell>
						<TableCell sx={{fontWeight: 'bold'}} align='center'>
							{canSubmit ? 'Nộp link' : 'Link'}
						</TableCell>
						{canSubmit || (
							<TableCell
								sx={{fontWeight: 'bold'}}
								align='center'></TableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody sx={{maxWidth: '100%'}}>
					{data.items &&
						!loading &&
						!err &&
						data.items.map((value) => (
							<TableRow key={value.work_id} >
								<TableCell sx={{maxWidth: '10rem'}}>
									<Link
										sx={{
											display: 'block',
											cursor: 'pointer',
											maxWidth: '100%',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis'
										}}
										href={value.link_description}
										target='_blank'
										onClick={e => downloadFile(e, value.link_description, value.name, auth.user.accessToken)}
									>
										{value.name}
									</Link>
								</TableCell>
								<TableCell align='center'>
									<p>
										{
											dateFormatter(
												new Date(value.deadline)
											)['HH:MM']
										}
									</p>
									<p>
										{
											dateFormatter(
												new Date(value.deadline)
											)['dd/mm/yyyy']
										}
									</p>
								</TableCell>
								<TableCell>
									<pre className='font-default'>
										{value.work_assigns
											.map((e) => e.employee.name)
											.join(',\n')}
									</pre>
								</TableCell>
								<TableCell
									sx={{
										color: TaskStatusStyle[value.state]
											?.color,
										fontSize: 20,
									}}>
									{(value.state !== 1 && value.state !== 5) ||
									!canManage ? (
										<Stack>
											<NowrapTypography
												sx={{fontSize: '20px'}}>
												{
													TaskStatusStyle[
														value.state
													]?.name
												}
											</NowrapTypography>
											{(value.state === 5 ||
												value.state === 4) && (
													<NowrapTypography
														sx={{
															color: 'red',
															fontSize:
																'20px',
														}}>
														(Trễ hạn)
													</NowrapTypography>
												)}
										</Stack>
									) : (
										<Stack>
											<Link
												sx={{
													color: TaskStatusStyle[
														value.state
													]?.color,
													cursor: 'pointer',
													textDecorationColor:
														TaskStatusStyle[
															value.state
														]?.color,
													':hover': {
														color: '#1976d2',
													},
												}}
												onClick={() =>
													openConfirmPendingTask(
														value.work_id
													)
												}>
												{
													<Stack>
														<NowrapTypography
															sx={{
																fontSize:
																	'20px',
															}}>
															{
																TaskStatusStyle[
																	value
																		.state
																]?.name
															}
														</NowrapTypography>
													</Stack>
												}
											</Link>
											{value.state ===
												TaskStatus.pending_expired && (
												<NowrapTypography
													sx={{
														color: 'red',
														fontSize: '20px',
													}}>
													(Trễ hạn)
												</NowrapTypography>
											)}
										</Stack>
									)}
								</TableCell>

								<TableCell align='center'>
									{canSubmit ? (
										(value.state === 1 ||
										value.state === 2 ||
										value.state === 4 || 
										value.state === 5) ? (
											'-'
										) : (
											<SubmitLinkModal
												handleSubmit={(links) =>
													{
														console.log(value)
														handleSubmitLink(
															value.work_id,
															links
														)
													}
												}
											/>
										)
									) : value.state !== 0 &&
									  value.state !== 3 ? (
										<SeeLinkModal
											linkItems={
												value.links ? value.links : []
											}
											onOpen={(links) =>
												onOpenSeeModal?.(value)
											}
											isLoading={
												value.links === undefined
											}
										/>
									) : (
										'-'
									)}
								</TableCell>
								{canManage && (
									<TableCell>
										<ActionButton
											deleteAble
											deleteAction={() =>
												openDeleteTaskConfirmModal(
													value.work_id
												)
											}
										/>
									</TableCell>
								)}
							</TableRow>
						))}
				</TableBody>
			</Table>
			<div className='flex flex-col items-center justify-center w-full'>
				{loading ? (
					<Stack className='my-6'>
						<CircularProgress />
					</Stack>
				) : err ? (
					<Typography
						sx={{fontWeight: 700, fontSize: '1.2rem', mt: 3, mb: 3}}>
						Có lỗi xảy ra.
					</Typography>
				) : (
					data.items.length === 0 && (
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: '1.2rem',
								mt: 3,
								mb: 3,
							}}>
							Không có kết quả nào.
						</Typography>
					)
				)}
				{data.total_pages > 0 && (
					<Stack spacing={2} className='my-6 flex flex-col items-center'>
						<Pagination
							color='primary'
							size='large'
							count={data.total_pages}
							page={data.current_page}
							onChange={onChangePage}
						/>
					</Stack>
				)}
			</div>

			<ConfirmModal
				title='Xác nhận công việc'
				noMsg='Chưa hoàn thành'
				yesMsg='Hoàn thành'
				confirmMsg='Xác nhận công việc này đã hoàn thành'
				open={open}
				handleClose={() => setOpen(false)}
				action={() => markTaskCompleted(true)}
				subAction={() => markTaskCompleted(false)}
			/>
			<ConfirmModal
				title='Xác nhận xóa công việc'
				confirmMsg='Hành động này không thể hoàn tác'
				open={deleteConfirmOpen}
				handleClose={() => setDeleteConfirmOpen(false)}
				action={deleteTask}
			/>
		</TableContainer>
	)
}

export default TaskTable

// TODO: Make Status text
// interface TaskStatusTextProps {
// 	state: TaskStatus
// 	underline?: boolean
// }

// function TaskStatusText({state, underline = false}: TaskStatusTextProps) {
// 	return (
// 		<>
// 			<p
// 				// className={clsx('hover:text-blue-700')}
// 				style={{
// 					color: TaskStatusStyle[state][0]?.color,
// 					textDecorationLine: underline ? 'underline' : 'none',
// 					textDecorationColor: TaskStatusStyle[state][0]?.color,
// 				}}>
// 				{TaskStatusStyle[state][0]?.name}
// 			</p>
// 			{TaskStatusStyle[state].length > 1 && (
// 				<p
// 					// className={clsx({'hover:text-[#1976d2]': underline})}
// 					style={{
// 						color: TaskStatusStyle[state][1].color,
// 						textDecorationLine: underline ? 'underline' : 'none',
// 						textDecorationColor: TaskStatusStyle[state][1].color,
// 					}}>
// 					{TaskStatusStyle[state][1].name}
// 				</p>
// 			)}
// 		</>
// 	)
// }
