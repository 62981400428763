import {Navigate} from 'react-router'
import {useState, useEffect} from 'react'
import {CircularProgress} from '@mui/material'
import {useAuth} from './auth'
import client from '../../services/axios'

export const RequireAuth = ({children}: any) => {
	const auth = useAuth()
	const [isCheck, setIsCheck] = useState(false)

	const checkUser = async () => {
		try {
			const res = await client.get('/v1/employee/current')
			const data = res.data.data
			const wardId = data.ward.ward_id
			const employeeId = data.employee_id 
			const level = data.level 
			const name = data.name
			auth.login({wardId, employeeId, level, name})
			setIsCheck(true)
			// console.log("isCheck: ", isCheck);
			
		} catch (error) {
			auth.logout()
			setIsCheck(true)
		}
	}

	useEffect(() => {
		// const accessToken = localStorage.getItem('accessToken')
		// if (!accessToken) {
		// 	return setIsCheck(true)
		// }
		checkUser()
	}, [])

	if (!isCheck) {
		// console.log("Render Loading");
		return (
			<div className='h-screen w-full flex justify-center items-center'>
				<CircularProgress />
			</div>
		)
	}

	else if (!auth.user.wardId) {
		// TODO: change to token when API available
		return <Navigate to='/login' />
	}

	else {
		// console.log("Render Main");
		return children
	}

}
