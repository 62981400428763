import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'

function CustomTable() {
	return (
		<Table sx={{width: '100px'}}>
      <TableHead>
        <TableRow>
          <TableCell style={{ maxWidth: '20%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            Cột đầu tiên
          </TableCell>
          <TableCell style={{ width: '20%' }}>Cột thứ hai</TableCell>
          <TableCell style={{ width: '60%' }}>Cột thứ ba</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell style={{ maxWidth: '1px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            Phần tử quá dài trong cột đầu tiên Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos velit voluptate assumenda amet iure voluptates natus dolore deleniti, qui quis eaque et magnam tenetur ut enim distinctio, quod sint veritatis.
          </TableCell>
          <TableCell>Phần tử trong cột thứ hai</TableCell>
          <TableCell>Phần tử trong cột thứ ba</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ maxWidth: '1px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
            Phần tử quá dài trong cột đầu tiên
          </TableCell>
          <TableCell>Phần tử trong cột thứ hai</TableCell>
          <TableCell>Phần tử trong cột thứ ba</TableCell>
        </TableRow>
      </TableBody>
    </Table>
	)
}

function TestPage() {
	return (
		<div className='w-[750px] overflow-hidden'>
			<CustomTable />
		</div>
	)
}

export default TestPage
