import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableCell as Cell,
	TableRow,
	Paper,
	styled,
	Grid,
	CircularProgress,
	Stack,
} from '@mui/material'
import {EmployeeWithWardsInfo} from '../../interfaces/employees'
import ActionButton from '../Button/ActionButton.tsx'
import dateFormatter from '../../helpers/formatters/dateFormatter'
import ConfirmModal from '../Modal/ConfirmModal'
import {useState} from 'react'
import EmployeeEditModal from '../Modal/EmployeeEditModal'
import EmployeeAddModal from '../Modal/EmployeeAddModal'
import { toast } from 'react-toastify'
import client from '../../services/axios'

export interface EmployeesTableData {
	employees: Array<EmployeeWithWardsInfo>
	refreshPage?: () => void
}

const TableCell = styled(Cell)`
	font-size: 1.25rem;
`

export const EmployeesTable = ({employees, refreshPage = () => {}}: EmployeesTableData) => {
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [currentEmployee, setCurrentEmployee] = useState({} as EmployeeWithWardsInfo)

	const openDeleteTaskConfirmModal = (): void => {
		setDeleteConfirmOpen(true)
	}
	const openEditModal = (): void => {
		setEditModalOpen(true)
	}

	const handleDeleteEmployee = async () => {
		try {
			const response = await toast.promise(client.delete(`/v1/manager/staff/${currentEmployee.employee_id}`, {
				params: {
					staff_id: currentEmployee.employee_id
				}
			}), {
				pending: 'Đang xóa cán bộ',
				success: 'Xóa thành công'
			})
			refreshPage()
			console.log(response)
		} catch (error) {
			toast.error('Có lỗi xảy ra')
		}
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{backgroundColor: '#e0e0e0'}}>
					<TableHead>
						<TableCell sx={{fontWeight: 800}}>Tên cán bộ</TableCell>
						<TableCell sx={{fontWeight: 800}}>Chức vụ</TableCell>
						<TableCell sx={{fontWeight: 800}}>Số điện thoại</TableCell>
						<TableCell sx={{fontWeight: 800}}>Đơn vị</TableCell>
					</TableHead>
	
					{employees.length ? (
						<TableBody>
							{employees.map((item) => (
								<TableRow sx={{backgroundColor: 'white'}}>
									<TableCell>{item.name}</TableCell>
									<TableCell>{item.position}
									</TableCell>
									<TableCell>{item.phone}</TableCell>
									<TableCell>{item.ward.name}</TableCell>
									<TableCell align='right'>
										<ActionButton
											edit
											deleteAble
											editAction={() => {
												openEditModal()
												setCurrentEmployee(item)
											}}
											deleteAction={() => {
												openDeleteTaskConfirmModal()
												setCurrentEmployee(item)
											}}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : <></>}
				</Table>
				<ConfirmModal
					title='Xác nhận xóa'
					confirmMsg='Hành động này không thể hoàn tác'
					open={deleteConfirmOpen}
					handleClose={() => setDeleteConfirmOpen(false)}
					action={handleDeleteEmployee}
				/>
				{Object.keys(currentEmployee).length ? <EmployeeEditModal handleClose={() => setEditModalOpen(false)} open={editModalOpen} original={currentEmployee} refreshPage={refreshPage} /> : <></>}
			</TableContainer>
			{
				!employees.length && (

					<Stack sx={{margin: '20px', width: '100%'}}>
						<CircularProgress sx={{marginX: 'auto'}} />
					</Stack>
				)
			}
		</>
	)
}
