import clsx from 'clsx'

interface RedAlertCircleProps {
	text?: string | number
	isAbsolute?: boolean
}

/**
 * Require Parent: style -> position: relative
 *
 */
function RedAlertCircle({text, isAbsolute = false}: RedAlertCircleProps) {
	
	if (!text || text === '0' || text === 0) {
		return null
	}
	return (
		<div
			className={clsx(
				'h-6 w-6 rounded-full bg-red-600 text-white font-normal flex flex-col items-center justify-center text-center',
				{
					['absolute right-2 top-1/2 -translate-y-1/2 m-0']: isAbsolute,
					['ml-1']: !isAbsolute,
				}
			)}>
			<p className='p-0 w-full h-min flex flex-col items-center justify-center'>{text}</p>
		</div>
	)
}

export default RedAlertCircle
