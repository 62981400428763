import React, {useState, useEffect} from 'react'
import {LinkData, TaskData, TaskStatus} from '../../interfaces/task'
import TaskTable from '../../components/TaskTable'
import {
	FormControl,
	Grid,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Typography,
} from '@mui/material'
import AddTaskModal from '../../components/Modal/AddTaskModal'
import {IPagination} from '../../interfaces/pagination'
import {defaultTaskData} from '../TaskManagement_CB'
import client from '../../services/axios'
import {toast} from 'react-toastify'
import useLoading from '../../hooks/useLoading'
import axios from 'axios'

export const WardList = {
	Leader: [
		{
			name: 'Phường 1',
			value: 2,
		},
		{
			name: 'Phường 2',
			value: 3,
		},
		{
			name: 'Phường 3',
			value: 4,
		},
		{
			name: 'Phường 4',
			value: 5,
		},
		{
			name: 'Phường 5',
			value: 6,
		},
		{
			name: 'Phường 6',
			value: 7,
		},
		{
			name: 'Phường 7',
			value: 8,
		},
		{
			name: 'Phường 8',
			value: 9,
		},
		{
			name: 'Phường 9',
			value: 10,
		},
		{
			name: 'Phường 10',
			value: 11,
		},
		{
			name: 'Phường 11',
			value: 12,
		},
		{
			name: 'Phường 12',
			value: 13,
		},
		{
			name: 'Phường 13',
			value: 14,
		},
		{
			name: 'Phường 14',
			value: 15,
		},
		{
			name: 'Cơ quan',
			value: 1,
		},
	],
	Manager: [
		{
			name: 'Phường 1',
			value: 2,
		},
		{
			name: 'Phường 2',
			value: 3,
		},
		{
			name: 'Phường 3',
			value: 4,
		},
		{
			name: 'Phường 4',
			value: 5,
		},
		{
			name: 'Phường 5',
			value: 6,
		},
		{
			name: 'Phường 6',
			value: 7,
		},
		{
			name: 'Phường 7',
			value: 8,
		},
		{
			name: 'Phường 8',
			value: 9,
		},
		{
			name: 'Phường 9',
			value: 10,
		},
		{
			name: 'Phường 10',
			value: 11,
		},
		{
			name: 'Phường 11',
			value: 12,
		},
		{
			name: 'Phường 12',
			value: 13,
		},
		{
			name: 'Phường 13',
			value: 14,
		},
		{
			name: 'Phường 14',
			value: 15,
		},
	],
}
export interface TaskAssignManagementProps {
	level: string
}

const TaskAssignManagement = ({level}: TaskAssignManagementProps) => {
	// return <></>

	const [currentWard, setCurrentWard] = useState(2)
	const [taskData, setTaskData] = useState<IPagination<TaskData>>(defaultTaskData)
	const [refreshKey, setRefreshKey] = useState(false)

	const [totalPages, setTotalPages] = useState(0)
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setTaskData({...taskData, current_page: value})
		refreshPage()
	}

	let controller: any = null

	// INFO: Loading UI
	const loader = useLoading()
	const refreshPage = () => {
		setRefreshKey(!refreshKey)
	}

	const fetchTasks = async () => {
		loader.setIsLoading(true)
		loader.setErr(false)
		if (controller) {
			// controller.abort()
			controller = null
		}

		controller = new AbortController()

		try {
			const response = await client.get('/v1/manager/get_works_by_ward', {
				params: {
					ward_id: currentWard,
					per_page: taskData.per_page,
					current_page: taskData.current_page,
				},
				signal: controller.signal,
			})
			const fetchedTaskData = response.data.data
			// console.log('fetched data', fetchedTaskData.total_pages, fetchedTaskData.total_items)
			setTaskData({...fetchedTaskData})
			// console.log('set data', taskData.total_pages, taskData.total_items)
			setTotalPages(fetchedTaskData.total_pages)
			// INFO: fetch task links
			const tasksWithLinks = await Promise.all(
				fetchedTaskData.items.map(async (task: TaskData) => {
					const getLinkRes = await client.get(
						`/v1/manager/work_links/${task.work_id}`,
						{
							params: {
								work_id: task.work_id,
							},
						}
					)
					const taskLinks: Array<string> =
						getLinkRes.data.data.items.reduce(
							(total: Array<string>, item: LinkData) => [
								...total,
								item.link,
							],
							[]
						)
					return {
						...task,
						links: taskLinks,
					}
				})
			)
			// console.log('taskdata with links: ', {...taskData, items: tasksWithLinks})
			setTaskData({
				...taskData,
				items: tasksWithLinks,
				total_items: fetchedTaskData.total_items,
				total_pages: fetchedTaskData.total_pages,
			})
			loader.setIsLoading(false)
			loader.setErr(false)
		} catch (err) {
			toast.error('Lấy dữ liệu thất bại')
			console.log(err)
			loader.setIsLoading(false)
			loader.setErr(true)
		}
	}

	useEffect(() => {
		fetchTasks()
	}, [currentWard, refreshKey])

	return (
		<div className='flex w-full h-screen overflow-y-auto flex-col p-8 items-start gap-4'>
			<div className='w-full flex flex-row justify-between items-center mb-16'>
				<Stack>
					<h1 className='text-4xl font-bold mb-5'>Quản lí giao việc</h1>
				</Stack>
				<div className='flex flex-row'>
					<AddTaskModal
						currentWard={currentWard}
						refreshPage={refreshPage}
						level={level}
					/>
				</div>
			</div>
			<Grid container>
				<Grid item xs={12} className='flex flex-row justify-end items-center'>
					<FormControl sx={{minWidth: 200, mb: 4}}>
						<Select
							labelId='demo-simple-select-autowidth-label'
							id='demo-simple-select-autowidth'
							value={currentWard}
							onChange={(e) => {
								setCurrentWard(Number(e.target.value))
							}}
							MenuProps={{
								PaperProps: {
									sx: {
										maxHeight: 200,
									},
								},
							}}>
							{WardList['Leader'].map(
								(item) => (
									<MenuItem
										key={item.value}
										value={item.value}>
										{item.name}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TaskTable
						data={taskData}
						canManage
						loading={loader.isLoading}
						err={loader.err}
						refreshPage={refreshPage}
						onChangePage={handleChange}
					/>
				</Grid>
				{/* <Grid item xs={12}>
					{totalPages ? (
						<Stack
							spacing={2}
							className='mt-6 flex flex-col items-center'>
							<Typography>Trang: {taskData.current_page}</Typography>
							<Pagination
								count={totalPages}
								page={taskData.current_page}
								onChange={handleChange}
							/>
						</Stack>
					) : <></>}
				</Grid> */}
			</Grid>
		</div>
	)
}

export default TaskAssignManagement
