import { Dispatch, useState, SetStateAction } from "react";

export interface Loading {
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    err: boolean,
    setErr: Dispatch<SetStateAction<boolean>>
}

function useLoading() {
    const [isLoading, setIsLoading] = useState(false)
    const [err, setErr] = useState(false)

    return {
        isLoading,
        setIsLoading,
        err,
        setErr
    }
}

export default useLoading