import {Button, Skeleton, styled} from '@mui/material'
import {useState, useEffect, useId, ChangeEvent} from 'react'
import InfoTable, {PasswordTable} from '../../components/InfoTable'
import useAccount from '../../hooks/useAccount'
import client from '../../services/axios'
import { EmployeeWithWardsInfo } from '../../interfaces/employees'
import dateFormatter, {
	convertToISODateString,
} from '../../helpers/formatters/dateFormatter'
import {toast} from 'react-toastify'
import { useAuth } from '../../components/Auth/auth'

const EditButton = styled(Button)`
	font-size: 1.15rem;
	padding: 0.6rem 0.825rem;
`

function AccountInfoPage() {
	const passwordId = useId()
	const account = useAccount()
	const tempAccount = useAccount()
	const auth = useAuth()

	const [password, setPassword] = useState('********')
	const [enableEditingInfo, setEnableEditingInfo] = useState(false)
	const [enableEditingPassword, setEnableEditingPassword] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const fetchingData = async () => {
		setIsLoading(true)
		try {
			const res = await client.get('/v1/employee/current')
			const data = res.data.data as EmployeeWithWardsInfo
			account.setFullName(data.name)
			account.setEmail(data.email)
			account.setPosition(data.position)
			// account.setGender(data.sex)
			// account.setBirthDate(dateFormatter(new Date(data.birth))['dd/mm/yyyy'])
			account.setPhoneNumber(data.phone)
			account.setUnit(data.ward.name)
			// account.setLevel(data.level)
			setIsLoading(false)
		} catch (error) {
			toast.error('Lỗi!')
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchingData()
	}, [])

	const handleEditInfoButtonClick = () => {
		setEnableEditingInfo(true)
		tempAccount.copyData(account)
	}

	const handleAcceptButtonClick = async (type: 'account' | 'password') => {
		if (type === 'account') {
			try {
				setIsLoading(true)
				const res = await toast.promise(
					client.put('/v1/employee/current', {
						name: account.fullName,
						phone: account.phoneNumber,
						position: account.position,
					}),
					{
						pending: 'Đang chỉnh sửa thông tin tài khoản',
						success: 'Chỉnh sửa thông tin tài khoản thành công',
						error: 'Thay đổi thông tin tài khoản thất bại',
					}
				)

				await fetchingData()
				setEnableEditingInfo(false)
				setIsLoading(false)
			} catch (error) {
				setIsLoading(false)
			}
		} else if (type === 'password') {
			setIsLoading(true)
			try {
				const res = await toast.promise(
					client.put('/v1/employee/current/password', {
						new_password: password,
					}),
					{
						pending: 'Đang chỉnh sửa mật khẩu',
						success: 'Chỉnh sửa mật khẩu thành công',
						error: 'Thay đổi mật khẩu thất bại',
					}
				)

				setPassword('********')
				setEnableEditingPassword(false)
				setIsLoading(false)
			} catch (error) {
				setIsLoading(false)
			}
		}
	}

	const handleCancelButtonClick = (type: 'account' | 'password') => {
		if (type === 'account') {
			account.copyData(tempAccount)
			setEnableEditingInfo(false)
		} else if (type === 'password') {
			setPassword('********')
			setEnableEditingPassword(false)
		}
	}

	const handleEditPasswordButtonClick = () => {
		setEnableEditingPassword(true)
		setPassword('')
		setTimeout(() => {
			const passwordInput = document.getElementById(passwordId) as HTMLInputElement
			passwordInput.focus()
		}, 50)
	}

	const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value)
	}

	if (isLoading) {
		return (
			<div className='w-[70%] min-w-[940px] flex flex-col p-8 gap-4 overflow-auto'>
				<div className='flex flex-row justify-between'>
					<h1 className='text-3xl font-bold'>Thông tin tài khoản</h1>
				</div>
				<Skeleton height={75}/>
				<div className='flex flex-row justify-between mt-12'>
					<h1 className='text-3xl font-bold'>Mật khẩu</h1>
				</div>
				<Skeleton height={75}/>
			</div>
		)
	}

	return (
		<div className='w-[70%] min-w-[940px] flex flex-col p-8 gap-4'>
			<div className='flex flex-row justify-between'>
				<h1 className='text-3xl font-bold'>Thông tin tài khoản</h1>
				{auth.user.level === 'Staff' ? null : enableEditingInfo ? (
					<div className='flex flex-row gap-2'>
						<EditButton
							variant='text'
							color='error'
							onClick={() => handleCancelButtonClick('account')}>
							Hủy
						</EditButton>
						<EditButton
							variant='outlined'
							onClick={() => handleAcceptButtonClick('account')}>
							Xác nhận
						</EditButton>
					</div>
				) : (
					<EditButton
						variant='contained'
						onClick={handleEditInfoButtonClick}>
						Chỉnh sửa
					</EditButton>
				)}
			</div>
			<InfoTable account={account} enableEditing={enableEditingInfo} />
			<div className='flex flex-row justify-between mt-12'>
				<h1 className='text-3xl font-bold'>Mật khẩu</h1>
				{enableEditingPassword ? (
					<div className='flex flex-row gap-2'>
						<EditButton
							variant='text'
							color='error'
							onClick={() => handleCancelButtonClick('password')}>
							Hủy
						</EditButton>
						<EditButton
							variant='outlined'
							onClick={() => handleAcceptButtonClick('password')}>
							Xác nhận
						</EditButton>
					</div>
				) : (
					<EditButton
						variant='contained'
						onClick={handleEditPasswordButtonClick}>
						Chỉnh sửa
					</EditButton>
				)}
			</div>
			<PasswordTable
				id={passwordId}
				password={password}
				onChange={handleChangePassword}
				enableEditing={enableEditingPassword}
			/>
		</div>
	)
}

export default AccountInfoPage
