import {isInterfaceDeclaration} from 'typescript'
import {EmployeeInfo, EmployeeWithWardsInfo} from './employees'
import {WardInfo} from './ward'

export enum TaskStatus {
	processing = 0,
	pending = 1,
	success = 2,
	expired = 3,
	success_expired = 4,
	pending_expired = 5,
}

// export const TaskStatusStyle = [
// 	{
// 		name: 'Đang xử lí',
// 		color: 'black',
// 	},
//     {
// 		name: 'Chờ duyệt',
// 		color: '#ffca28',
// 	},
//     {
// 		name: 'Hoàn thành',
// 		color: 'green',
// 	},
//     {
// 		name: 'Trễ hạn',
// 		color: 'red',
// 	},
//     {
// 		name: 'Hoàn thành (trễ hạn)',
// 		color: 'green',
// 	},
//     {
// 		name: 'Chờ duyệt (trễ hạn)',
// 		color: '#ffca28',
// 	},
// ]

export const TaskStatusStyle = [
	{
		name: 'Đang xử lí',
		color: 'black',
	},
	{
		name: 'Chờ duyệt',
		color: '#ffca28',
	},
	{
		name: 'Hoàn thành',
		color: 'green',
	},
	{
		name: 'Trễ hạn',
		color: 'red',
	},
	{
		name: 'Hoàn thành',
		color: 'green',
	},
	{
		name: 'Chờ duyệt',
		color: '#ffca28',
	},
]

export interface WorkAssignInfo {
	work_assign_id: number
	updated_at: string
	created_at: string
	employee: EmployeeInfo
}
export interface TaskData {
	work_id: number
	name: string
	link_description: string
	created_by: EmployeeInfo
	employee_assigned: EmployeeWithWardsInfo[]
	ward: WardInfo
	state: TaskStatus
	deadline: string
	links?: string[]
	created_at?: string
	updated_at?: string
	complete_at?: string
	manager: EmployeeInfo
	work_assigns: WorkAssignInfo[]
}

export interface SmTaskData {
	work_id: number
	ward_id: number
	name: string
	state: number
	link_description: string
	created_by: number
	created_by_info: EmployeeInfo
	created_at: string
	updated_at: string
	deadline: string
	complete_at?: string
}

export interface LinkData {
	work_link_id: number
	work_id: number
	link: string
	created_by: Object
	updated_at: string
	created_at: string
}
