import React, {useState, useEffect, ChangeEvent} from 'react'
import TaskTable from '../../components/TaskTable'
import {TaskStatus, TaskData, LinkData} from '../../interfaces/task'
import {IPagination} from '../../interfaces/pagination'
import client from '../../services/axios'
import {useAuth} from '../../components/Auth/auth'
import {toast} from 'react-toastify'

export const defaultTaskData: IPagination<TaskData> = {
	per_page: 5,
	current_page: 1,
	total_items: 0,
	total_pages: 0,
	items: [],
}

function TaskManagementPage_CB() {
	const [taskData, setTaskData] = useState<IPagination<TaskData>>(defaultTaskData)
	const [myPage, setMyPage] = useState(1)
	const [myTaskLoading, setMyTaskLoading] = useState(false)
	const [otherTaskData, setOtherTaskData] =
		useState<IPagination<TaskData>>(defaultTaskData)
	const [otherPage, setOtherPage] = useState(1)
	const [otherTaskLoading, setOtherTaskLoading] = useState(false)

	const auth = useAuth()

	const fetchingMyTask = async () => {
		try {
			setMyTaskLoading(true)
			const level = auth.user.level?.toLowerCase()
			const resTaskData = await client.get(`/v1/employee/works`, {
				params: {per_page: 5, current_page: myPage},
			})
			const data = resTaskData.data.data as IPagination<TaskData>

			setTaskData(data)
			setMyTaskLoading(false)
		} catch (error) {
			toast.error('Lấy dữ liệu thất bại')
			setMyTaskLoading(false)
		}
	}

	const fetchingOtherTask = async () => {
		try {
			setOtherTaskLoading(true)
			const level = auth.user.level?.toLowerCase()
			const resOtherTask = await client.get(
				`/v1/employee/works?current_ward=${true}`,
				{params: {per_page: 5, current_page: otherPage}}
			)
			const otherData = resOtherTask.data.data as IPagination<TaskData>

			setOtherTaskData(otherData)
			setOtherTaskLoading(false)
		} catch (error) {
			toast.error('Lấy dữ liệu thất bại')
			setOtherTaskLoading(false)
		}
	}

	const fetchingData = () => {
		fetchingMyTask()
		fetchingOtherTask()
	}

	useEffect(() => {
		fetchingMyTask()
	}, [myPage])

	useEffect(() => {
		fetchingOtherTask()
	}, [otherPage])

	const handleOpenSeeModal = async (task: TaskData) => {
		try {
			const resLinks = await client.get(`/v1/staff/work_links/${task.work_id}`)
			const links = resLinks.data.data as IPagination<LinkData>
			task.links = links.items.map((value) => value.link)
		} catch (error) {
			task.links = undefined
		}

		setTaskData({...otherTaskData})
		setOtherTaskData({...otherTaskData})
	}

	// if (auth.user.level === 'Manager')
	// 	return (
	// 		<div className='flex w-full h-screen items-center justify-center'>
	// 			Coming Soon
	// 		</div>
	// 	)

	const handleChangeMyPage = (e: ChangeEvent<unknown>, page: number) => {
		setMyPage(page)
		setTaskData({...taskData, current_page: page})
	}

	const handleChangeOtherPage = (e: ChangeEvent<unknown>, page: number) => {
		setOtherPage(page)
		setOtherTaskData({...otherTaskData, current_page: page})
	}

	return (
		<div className='flex flex-col p-8 items-start gap-4'>
			<h1 className='text-4xl font-bold mb-8'>Quản lí công việc</h1>
			<h2 className='text-3xl font-bold'>Công việc của tôi</h2>
			<TaskTable
				data={taskData}
				loading={myTaskLoading}
				canSubmit
				refreshPage={fetchingData}
				onOpenSeeModal={handleOpenSeeModal}
				onChangePage={handleChangeMyPage}
			/>
			<h2 className='text-3xl font-bold mt-4'>Công việc của đơn vị</h2>
			<TaskTable
				data={otherTaskData}
				loading={otherTaskLoading}
				refreshPage={fetchingData}
				onOpenSeeModal={handleOpenSeeModal}
				onChangePage={handleChangeOtherPage}
			/>
		</div>
	)
}

export default TaskManagementPage_CB
