import client from "../services/axios"

export const downloadFile = async (e: React.SyntheticEvent, file_link: string, file_name: string, token: string | undefined) => {
    try {
        e.preventDefault()
        let file_type :string = 'pdf'
        if (file_link.includes('docx')) {
            file_type = 'vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
        const response = await client.get(file_link, {
            headers: {
                Authorization: 'Bearer ' + token
            },
            responseType: "blob"
        })
        const file = new Blob([response.data], { type: `application/${file_type}` });
        const fileURL = URL.createObjectURL(file);
        let a = document.createElement("a");
        a.href = fileURL;
        a.download = `${file_name}.${file_type === 'pdf' ? 'pdf' : 'docx'}`;
        a.click();
    } catch (error) {
        console.log(error)
    }
}