import {useState, Dispatch, SetStateAction} from 'react'

export interface Account {
	fullName: string
	position: string
	email: string
	// birthDate: string
	phoneNumber: string
	unit: string
	setFullName?: Dispatch<SetStateAction<string>>
	setPosition?: Dispatch<SetStateAction<string>>
    setEmail?: Dispatch<SetStateAction<string>>
    // setBirthDate?: Dispatch<SetStateAction<string>>
    setPhoneNumber?: Dispatch<SetStateAction<string>>
    setUnit?: Dispatch<SetStateAction<string>>
    copyData?: (account: Account) => void
}

/**
 * const oldAccount = useAccount(...);
 * 
 * const newAccount = useAccount(...);
 * 
 * newAccount.copyData(oldAccount); // This method supports to copy data from account to another account
 * 
 */
function useAccount(
	fullName: string = '',
	position: string = '',
	email: string = '',
	phoneNumber: string = '',
	unit: string = '',
	// birthDate: string = '',
) 
{
	const [_email, setEmail] = useState(email)
	const [_fullName, setFullName] = useState(fullName)
	const [_position, setPosition] = useState(position)
	// const [_birthDate, setBirthDate] = useState(birthDate)
	const [_phoneNumber, setPhoneNumber] = useState(phoneNumber)
	const [_unit, setUnit] = useState(unit)

    const copyData = (account: Account) => {
        setFullName(account.fullName);
		setPosition(account.position);
        setEmail(account.email);
        // setBirthDate(account.birthDate);
        setPhoneNumber(account.phoneNumber);
        setUnit(account.unit);
    }

	return {
		fullName: _fullName,
		position: _position,
		email: _email,
		// birthDate: _birthDate,
		phoneNumber: _phoneNumber,
		unit: _unit,
		setFullName,
		setPosition,
		setEmail,
		// setBirthDate,
		setPhoneNumber,
		setUnit,
        copyData,
	}
}

export default useAccount
