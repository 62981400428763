import {useState, useEffect} from 'react'
import NotificationItem from '../../components/NotificationItem'
import {AlertData, NotificationData, AlertType} from '../../interfaces/notification'
import {IPagination} from '../../interfaces/pagination'
import {IUnseenAlert, IUnseenNotification} from '../../interfaces/notification'
import {Skeleton, Stack, Pagination, Tabs, Tab} from '@mui/material'
import client from '../../services/axios'
import {toast} from 'react-toastify'
import useLoading from '../../hooks/useLoading'
import TabPanel, {createTabProps} from '../../components/TabPanel'
import {RedAlertCircle} from '../../components/RedAlertCircle'
import {useUnseenNotification} from '../../components/UnseenNotificationProvider'

type IAlert = IPagination<AlertData> & IUnseenAlert
type INotification = IPagination<NotificationData> & IUnseenNotification

export const defaultAlert: IPagination<any> & IUnseenAlert = {
	per_page: 5,
	current_page: 1,
	total_items: 0,
	total_pages: 0,
	total_unseen_alert_number: 0,
	total_unseen_alert_seen_id_list: [],
	items: [],
}

export const defaultNotifications: IPagination<any> & IUnseenNotification = {
	per_page: 5,
	current_page: 1,
	total_items: 0,
	total_pages: 0,
	total_unseen_notification_number: 0,
	total_unseen_notification_id_list: [],
	items: [],
}

function NotificationPage_CB() {
	const [notifications, setNotifications] = useState<IAlert>(defaultAlert)
	const [receivedNotis, setReceivedNotis] =
		useState<INotification>(defaultNotifications)
	const alertLoader = useLoading()
	const receivedLoader = useLoading()
	const [currentTab, setCurrentTab] = useState(0)
	const unseen = useUnseenNotification()

	const fetchData = async () => {
		alertLoader.setIsLoading(true)
		try {
			const res = await client.get('/v1/staff/alert', {
				params: {
					per_page: 5,
					current_page: notifications.current_page,
				},
			})
			const data = res.data.data
			setNotifications(data)
			alertLoader.setIsLoading(false)
		} catch (error) {
			toast.error('Lỗi! Không thể tải được thông báo')
			alertLoader.setIsLoading(false)
		}
	}

	const fetchReceivedNotis = async () => {
		receivedLoader.setIsLoading(true)
		try {
			const res = await client.get('/v1/employee/notifications', {
				params: {
					per_page: 5,
					current_page: receivedNotis.current_page,
				},
			})
			const data = res.data.data
			setReceivedNotis(data)
			receivedLoader.setIsLoading(false)
		} catch (error) {
			toast.error('Lỗi! Không thể tải được thông báo được nhận')
			receivedLoader.setIsLoading(false)
		}
	}

	// Mark as seen for Alert
	const markSeenAlert = async () => {
		try {
			const res = await client.put('/v1/staff/alert_seen', {total_unseen_alert_seen_id_list: unseen.total_unseen_alert_seen_id_list})
			unseen.refresh()
		} catch (error) {
			
		}
	}

	// Mark as seen for Notification
	const markSeenNotification = async () => {
		try {
			const res = await client.put('/v1/employee/notifications_seen', {total_unseen_notification_id_list: unseen.total_unseen_notification_id_list})
			unseen.refresh()
		} catch (error) {
			
		}
	}

	useEffect(() => {
		fetchData()
	}, [notifications.current_page])

	useEffect(() => {
		fetchReceivedNotis()
	}, [receivedNotis.current_page])

	// Handle RedAlertCircle
	useEffect(() => {
		if (
			!alertLoader.isLoading &&
			currentTab === 0 &&
			unseen.total_unseen_alert_number > 0
		) {
			markSeenAlert()
		} else if (
			!receivedLoader.isLoading &&
			currentTab === 1 &&
			unseen.total_unseen_notification_number > 0
		) {
			markSeenNotification()
		}
	}, [
		alertLoader.isLoading,
		receivedLoader.isLoading,
		currentTab,
		unseen.total_unseen_alert_number,
		unseen.total_unseen_notification_number,
	])

	const onChangeAlertPage = (
		event: React.ChangeEvent<unknown>,
		value: number
	): void => {
		setNotifications({
			...notifications,
			current_page: value,
		})
	}

	const onChangeNotificationPage = (
		event: React.ChangeEvent<unknown>,
		value: number
	): void => {
		setReceivedNotis({
			...receivedNotis,
			current_page: value,
		})
	}

	const handleChangeTab = (event: React.SyntheticEvent, value: number) => {
		setCurrentTab(value)
	}

	return (
		<div className='w-full h-screen flex flex-col text-left gap-4 p-4 overflow-auto'>
			<h1 className='text-3xl font-bold mb-6'>Thông báo</h1>
			<Tabs value={currentTab} onChange={handleChangeTab}>
				<Tab
					label='Đơn vị'
					{...createTabProps(0)}
					icon={<RedAlertCircle text={unseen.total_unseen_alert_number} />}
					iconPosition='end'
				/>
				<Tab
					label='Người dùng'
					{...createTabProps(1)}
					icon={
						<RedAlertCircle
							text={unseen.total_unseen_notification_number}
						/>
					}
					iconPosition='end'
				/>
			</Tabs>

			<TabPanel className='flex flex-col gap-4' value={currentTab} index={0}>
				{alertLoader.isLoading ? (
					<>
						<Skeleton height={'100px'} />
						<Skeleton height={'100px'} />
						<Skeleton height={'100px'} />
					</>
				) : notifications.items.length > 0 ? (
					notifications.items.map((noti) => (
						<NotificationItem
							key={noti.alert_id}
							type={noti.state}
							createdAt={new Date(noti.created_at)}
							unit={noti.ward.name}
							assignees={noti.alert_employee.map(
								(value) => value.employee.name
							)}
							taskName={noti.work.name}
							reviewer={noti.work.created_by.name}
						/>
					))
				) : (
					<div className='w-full text-2xl font-bold text-center'>
						Không có thông báo
					</div>
				)}

				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{flex: 1, justifySelf: 'flex-end'}}>
					<Pagination
						color='primary'
						size='large'
						count={notifications.total_pages}
						page={notifications.current_page}
						onChange={onChangeAlertPage}
					/>
				</Stack>
			</TabPanel>

			<TabPanel className='flex flex-col gap-4' value={currentTab} index={1}>
				{receivedLoader.isLoading ? (
					<>
						<Skeleton height={'100px'} />
						<Skeleton height={'100px'} />
						<Skeleton height={'100px'} />
					</>
				) : receivedNotis.items.length > 0 ? (
					receivedNotis.items.map((noti) => (
						<NotificationItem
							key={noti.notification_id}
							type={AlertType.receiving}
							createdAt={new Date(noti.created_at)}
							reviewer={noti.creator.name}
							assignees={noti.notification_employee.map(
								(ne) => ne.employee.name
							)}
							title={noti.header}
							content={noti.content}
							attachments={noti.notification_link}
						/>
					))
				) : (
					<div className='w-full text-2xl font-bold text-center'>
						Không có thông báo
					</div>
				)}
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{flex: 1, justifySelf: 'flex-end'}}>
					<Pagination
						color='primary'
						size='large'
						count={receivedNotis.total_pages}
						page={receivedNotis.current_page}
						onChange={onChangeNotificationPage}
					/>
				</Stack>
			</TabPanel>
		</div>
	)
}

export default NotificationPage_CB
