import {
	Modal,
	Box,
	Typography,
	Button,
	Stack,
	InputLabel,
	FormControl,
	Select,
	MenuItem,
	TextField,
	Chip,
	CircularProgress,
	InputAdornment,
	IconButton
} from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {LocalizationProvider, DateTimePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import React, {useEffect, useState} from 'react'
import {WardList} from '../../../pages/TaskAssignManagementPage'
import client from '../../../services/axios'
import {EmployeeInfo} from '../../../interfaces/employees'
import dayjs from 'dayjs'
import {toast} from 'react-toastify'
import useLoading from '../../../hooks/useLoading'
import 'dayjs/locale/en-gb'
import axios from 'axios'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 700,
	backgroundColor: 'white',
	border: '3px solid #1976d2',
	boxShadow: 24,
	padding: 4,
}

export interface TaskForm {
	name: string
	file?: File | null
	ward_id: number
	employee_id_list: Array<number>
	deadline: string | undefined
}

const initialFormData: TaskForm = {
	name: '',
	file: null,
	ward_id: 2,
	employee_id_list: [],
	deadline: dayjs().format('YYYY-MM-DD HH:mm:ss'),
}

export interface AddTaskModalProps {
	currentWard: number
	refreshPage: () => void
	level: string
}

const AddTaskModal = ({currentWard, refreshPage, level}: AddTaskModalProps) => {
	const [open, setOpen] = useState(false)
	const [nameError, setNameError] = useState(false)
	const [formWardId, setFormWardId] = useState(2)
	const [shrinkFileLabel, setShrinkFileLabel] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => {
		setOpen(false)
		setFormData({...formData, employee_id_list: []})
	}
	const [staffOpen, setStaffOpen] = useState(false)
	const handleStaffSelectOpen = () => {
		setStaffOpen(true)
	}
	const loader = useLoading()
	let controller: any = null

	// INFO: Form data
	const [employees, setEmployees] = useState<Array<EmployeeInfo>>([])
	const [formData, setFormData] = useState(initialFormData)
	// INFO: fetch employees
	const fetchEmployees = async (id: number) => {
		loader.setIsLoading(true)
		loader.setErr(false)

		if (controller) {
			// controller.abort()
			controller = null
		}

		controller = new AbortController()

		try {
			const response = await client.get(`/v1/manager/available_employees`, {
				params: {
					ward_id: id,
					allEmployeeOfWard: 0,
				},
				signal: controller.signal
			})
			setEmployees(response.data.data.items)
			loader.setIsLoading(false)
		} catch (error) {
			loader.setErr(true)
			loader.setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchEmployees(formWardId)
	}, [formWardId])
	useEffect(() => {
		setFormData(initialFormData)
		setFormWardId(2)
	}, [open])

	const handleAddTask = async () => {
		// console.log(formData)
		if (!formData.file || !formData.deadline || !formData.employee_id_list.length || nameError || !formData.ward_id) {
			toast.error("Vui lòng chọn đủ các trường")
			return
		}
		try {
			const response = await toast.promise(
				client.post('/v1/manager/work', formData, {
					headers: {'Content-Type': 'multipart/form-data'},
				}),
				{
					pending: 'Đang xử lí',
					success: 'Thêm công việc thành công',
				}
			)
			if (formData.ward_id === currentWard) refreshPage()
			handleClose()
		} catch (error) {
			console.log(error)
			toast.error('Có lỗi xảy ra')
		}
	}

	// console.log('formData: ',formData)

	return (
		<div>
			<Button onClick={handleOpen} sx={{mr: 2}} variant='contained' size='large'>
				Thêm công việc
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style} className='flex flex-col gap-4'>
					<div className='flex flex-row mb-10'>
						<Typography
							id='modal-modal-title'
							variant='h5'
							component='h2'
							className='font-bold text-center flex-1'>
							Thêm công việc
						</Typography>
					</div>

					<Stack>
						<TextField
							variant='outlined'
							label='Tên công việc'
							id='name'
							name='taskName'
							fullWidth
							placeholder='Nhập tên công việc'
							sx={{marginTop: 2}}
							value={formData.name}
							onChange={(e) =>
								setFormData({...formData, name: e.target.value})
							}
							required
							error={nameError}
							helperText={nameError && `Không bỏ trống tên công việc`}
							inputProps={{
								onBlur: (e) => {
									if (e.target.value.length) setNameError(false)
									else setNameError(true)
								},
								onFocus: (e) => {
									setNameError(false)
								},
							}}
						/>
					</Stack>

					<Stack>
						<input
							accept='.pdf, .docx, .doc'
							style={{
								display: 'none'
							}}
							id='file-uploader'
							type='file'
							onChange={(e) => {
								if (!e.target.files) return
								if ((e.target.files[0].size / 1024) / 1024 >= 21 ) {
									toast.error('Vui lòng tải lên file không quá 20MB')
									e.target.value = ""
									setFormData({
										...formData,
										file: null
									})
									return
								}
								setFormData({
									...formData,
									file: e.target.files[0],
								})
								setShrinkFileLabel(true)
							}}
						/>
						<TextField
							label='Tải tệp công việc lên'
							id='file-label'
							required
							value={formData.file?.name}
							inputProps={{
								readOnly: true,
							}}
							InputLabelProps={{
								shrink: shrinkFileLabel
							}}
							InputProps={{
								endAdornment: <InputAdornment position='end'>
									<IconButton onClick={() => {
										const fileUploader = document.getElementById('file-uploader')
										const fileLabel = document.getElementById('file-label')
										fileLabel?.focus()
										fileUploader?.click()
									}}
										sx={{marginRight: '-10px'}}
									>
										<UploadFileIcon />
									</IconButton>
								</InputAdornment>,
							}}
							sx={{
								paddingRight: 0
							}}
						/>
					</Stack>

					<Stack>
						<LocalizationProvider dateAdapter={AdapterDayjs}
						adapterLocale='en-gb'>
							<DateTimePicker
								label='Hạn chót *'
								defaultValue={dayjs()}
								onChange={(newValue) => {
									if (!newValue) return
									if (newValue.isValid()) {
										setFormData({
											...formData,
											deadline:
											newValue?.format(
													'YYYY-MM-DD HH:mm:ss'
												),
										})
									}
								}}
							/>
						</LocalizationProvider>
					</Stack>

					<Stack>
						<FormControl sx={{minWidth: 100}}>
							<InputLabel id='demo-simple-select-autowidth-label'>
								Chọn đơn vị *
							</InputLabel>
							<Select
								labelId='demo-simple-select-autowidth-label'
								id='demo-simple-select-autowidth'
								value={formData.ward_id}
								onChange={(e) => {
									setFormWardId(Number(e.target.value))
									setFormData({
										...formData,
										ward_id: Number(e.target.value),
										employee_id_list: []
									})
								}}
								// disabled={loader.isLoading}
								label='Chọn đơn vị *'
								required
								MenuProps={{
									PaperProps: {
										sx: {
											maxHeight: 200,
										},
									},
								}}>
								{WardList['Leader'].map((item) => (
									<MenuItem
										value={item.value}
										key={item.value}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
					<Stack>
						<FormControl sx={{minWidth: 100}}>
							<InputLabel id='demo-simple-select-autowidth-label'>
								Chọn người đảm nhận *
							</InputLabel>
							<Select
								labelId='demo-simple-select-autowidth-label'
								id='demo-simple-select-autowidth'
								value=''
								required
								disabled={!(formData.ward_id + 1)}
								onChange={(e) => {
									if (formData.employee_id_list.find(item => Number(e.target.value) === item)) return
									setFormData({
										...formData,
										employee_id_list: [
											...formData.employee_id_list,
											Number(e.target.value),
										],
									})
								}
								}
								label='Chọn người đảm nhận *'
								open={staffOpen}
								onOpen={handleStaffSelectOpen}
								onClose={() => setStaffOpen(false)}
								MenuProps={{
									PaperProps: {
										sx: {
											maxHeight: 200,
										},
									},
								}}>
								{loader.isLoading ? (
									<CircularProgress className='ml-[260px]' />
								) : loader.err ? (
									<Typography className='pl-2'>Có lỗi xảy ra</Typography>
								) : (
									employees.length ? employees.map((employee) => (
										<MenuItem value={employee.employee_id} key={employee.employee_id}>
											{employee.name} - {employee.phone}
										</MenuItem>
									)) : <Typography className='pl-2'>Không tìm thấy kết quả nào</Typography>
								)}
							</Select>
						</FormControl>
						<Typography className='font-light pl-3 pt-3'>
							Người đảm nhận:
						</Typography>
						<Stack
							sx={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'wrap',
								gap: 1,
							}}>
							{formData.employee_id_list.map((item: number) => {
								const name = employees.find(
									(employee) => employee.employee_id === item
								)?.name
								return (
									<Chip
										onDelete={() =>
											setFormData({
												...formData,
												employee_id_list: [
													...formData.employee_id_list.filter(
														(id) => id !== item
													),
												],
											})
										}
										label={name}
										color='primary'
										key={item}
									/>
								)
							})}
						</Stack>
					</Stack>

					<div className='flex flex-row mt-7 justify-end'>
						<Button
							onClick={handleClose}
							sx={{mr: '4px'}}
							variant='text'
							size='large'
							color='error'>
							Hủy
						</Button>
						<Button
							onClick={handleAddTask}
							variant='outlined'
							size='large'
							color='primary'>
							Xác nhận
						</Button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}

export default AddTaskModal
