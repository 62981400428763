import {Box, Typography} from '@mui/material'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
	className?: string
}

export function createTabProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

function TabPanel({children, value, index, ...other}: TabPanelProps) {
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && children}
		</div>
	)
}

export default TabPanel
