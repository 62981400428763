import {Box, Button, IconButton, Modal, Stack, Typography} from '@mui/material'

export interface ConfirmModalProps {
	open: boolean
	confirmMsg: string
	title?: string
	noMsg?: string
	yesMsg?: string
	action: () => void
	subAction?: () => void
	handleClose: () => void
}
const ConfirmModal = ({
	open,
	confirmMsg,
	title,
	noMsg,
	yesMsg,
	action,
	handleClose,
	subAction = () => {}
}: ConfirmModalProps) => {
	return (
		<Stack>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box className='box-modal-style'>
					<div className='flex flex-row mb-6'>
						<div className='flex flex-col w-full'>
							<div className='flex flex-row justify-between w-full'>
								<Typography
									id='modal-modal-title'
									variant='h5'
									component='h2'
									className='font-bold text-center flex-1'>
									{title}
								</Typography>
								<IconButton onClick={handleClose}>
									<svg
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
											fill='black'
										/>
									</svg>
								</IconButton>
							</div>

							<Typography align='center'>{confirmMsg}</Typography>
						</div>
					</div>
					<div className='flex flex-row mt-7 justify-center'>
						<Button
							onClick={() => {
								subAction()
								handleClose()
							}}
							sx={{mr: '4px'}}
							variant='text'
							color='error'>
							{noMsg || 'Hủy'}
						</Button>
						<Button
							onClick={() => {
								handleClose()
								action()
							}}
							variant='outlined'
							color='primary'>
							{yesMsg || 'Xác nhận'}
						</Button>
					</div>
				</Box>
			</Modal>
		</Stack>
	)
}

export default ConfirmModal
