import {createContext, useState, useEffect} from 'react'
import {
	INotificationContext,
	IUnseenAlert,
	IUnseenNotification,
} from '../../interfaces/notification'
import client from '../../services/axios'
import {useAuth} from '../Auth/auth'
import useSocketIO from '../../hooks/useSocketIO'

export const NotificationContext = createContext<INotificationContext>(
	{} as INotificationContext
)

interface ISocketNewNotification {
	type: 'alert' | 'notification'
	state: number
	employee_id_list: number[]
}

/**Used to load quantity of unseen notification and alert
 * Used for RedAlertCircle
 * Integrate SocketIO to Realtime update notification and alert
 */
export default function UnseenNotificationProvider({children}: any) {
	const [totalUnseenAlert, setTotalUnseenAlert] = useState(0)
	const [listUnseenAlert, setListUnseenAlert] = useState<number[]>([])
	const [totalUnseenNotification, setTotalUnseenNotification] = useState(0)
	const [listUnseenNotification, setListUnseenNotification] = useState<number[]>([])
	const auth = useAuth()
	const socket = useSocketIO()

	const refreshAlert = async () => {
		try {
			const level = auth.user.level === 'Staff' ? 'staff' : 'manager'
			const res = await client.get(`/v1/${level}/alert`)
			const data = res.data.data as IUnseenAlert

			const total = data.total_unseen_alert_number
			const list = data.total_unseen_alert_seen_id_list

			setTotalUnseenAlert(total)
			setListUnseenAlert(list)
		} catch (error) {}
	}

	const refreshNotification = async () => {
		try {
			const res = await client.get('/v1/employee/notifications')
			const data = res.data.data as IUnseenNotification

			const total = data.total_unseen_notification_number
			const list = data.total_unseen_notification_id_list

			setTotalUnseenNotification(total)
			setListUnseenNotification(list)
		} catch (error) {}
	}

	const refresh = () => {
		refreshAlert()
		refreshNotification()
	}

	useEffect(() => {
		refresh()
	}, [])

	// This useEffect is used to init and run socket
	useEffect(() => {
		if (socket) {
			socket?.emit('join-room', auth.user.wardId)
			// Listener of new-notification event
			const handleNewNotification = (data: ISocketNewNotification) => {
				// console.log('data: ', data)
				if (
					data.type === 'notification' &&
					data.employee_id_list.includes(auth.user.employeeId as number)
				) {
					refreshNotification()
				}
			}
			// Listener of new-notification event
			const handleNewAlert = (data: ISocketNewNotification) => {
				// console.log('data: ', data)
				if (data.type === 'alert') {
					if (data.state !== 4) {
						refreshAlert()
					} else if (
						data.employee_id_list.includes(
							auth.user.employeeId as number
						)
					) {
						refreshAlert()
					}
				}
			}
			socket?.on('new-notification', handleNewNotification)
			socket?.on('new-alert', handleNewAlert)

			return () => {
				socket?.off('new-notification', handleNewNotification)
				socket?.off('new-alert', handleNewAlert)
			}
		}
	}, [socket])

	return (
		<NotificationContext.Provider
			value={{
				total_unseen_alert_number: totalUnseenAlert,
				total_unseen_notification_number: totalUnseenNotification,
				total_unseen_alert_seen_id_list: listUnseenAlert,
				total_unseen_notification_id_list: listUnseenNotification,
				socket,
				refresh,
			}}>
			{children}
		</NotificationContext.Provider>
	)
}
