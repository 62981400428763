import {Paper, Stack, Typography} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

export interface CarbonCopyAlertCardProps {
	sender_name?: boolean // true: show sender's name, false: show receivers' names
	sender?: string
	receivers: Array<string>
	title: string
	time: string
    onClick?: () => void
}

const CarbonCopyAlertCard = ({sender_name = false, sender, receivers, title, time, onClick = () => {}}: CarbonCopyAlertCardProps) => {
	return (
		<Paper
			elevation={3}
			square={true}
			sx={{ 
                ':hover': {
                    backgroundColor: '#C2DBFF',
                    cursor: 'pointer'
                }
            }}
            onClick={onClick}
        >
			<Stack direction='row' spacing={4} padding='12px 6px'>
				<Typography align='left' noWrap={true} sx={{maxWidth: '170px'}}>
					{
						sender_name ? `${sender}` : `Đến: ${receivers.join(', ')}`
					}
				</Typography>
				<Typography align='left'>{title}</Typography>
				<Typography align='right' className='flex-1'>
					{dayjs(time).format('HH:mm DD/MM/YYYY')}
				</Typography>
			</Stack>
		</Paper>
	)
}

export default CarbonCopyAlertCard
