import {io, Socket} from "socket.io-client";
import { useState, useEffect } from "react";

function useSocketIO() {
    const [socket, setSocket] = useState<Socket | null>(null)

    useEffect(() => {
        const BASE_URL = process.env.REACT_APP_BASE_URL as string;
        const newSocket = io(BASE_URL, {
            auth: {
                token: localStorage.getItem('accessToken')
            }
        })
        newSocket.connect()
        setSocket(newSocket)

        return () => {
            newSocket?.disconnect();
        }
    }, []);

    return socket;
}

export default useSocketIO;