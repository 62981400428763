import {
	Modal,
	Box,
	Typography,
	Button,
	TextField,
	IconButton,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	InputAdornment,
} from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import 'dayjs/locale/en-gb'
import React, {useState} from 'react'
import {WardList} from '../../../pages/TaskAssignManagementPage'
import {useAuth} from '../../Auth/auth'
import { toast } from 'react-toastify'
import client from '../../../services/axios'

export interface EmployeeAddInfo {
	name: string,
	position: string,
	phone: string,
	ward_id: number,
	email: string,
	password: string,
	confirm_password: string,
}

export interface EmployeeAddModalProps {
	refreshPage: () => void
}

const EmployeeAddModal = ({ refreshPage }: EmployeeAddModalProps) => {
	const [open, setOpen] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)
	const auth = useAuth()
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)

	// INFO: Form data
	const [name, setName] = useState('')
	const [title, setTitle] = useState('')
	const [ward, setWard] = useState(2)
	const [phone, setPhone] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirm_password, setConfirm_password] = useState('')

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%) scale(0.95)',
		width: 700,
		backgroundColor: 'white',
		border: '2px solid #0348FD',
		boxShadow: 24,
		padding: 4,
	}

	const handleClickShowPassword = (check: boolean = false) => {
		check ? setShowConfirmPassword((prev) => !prev) : setShowPassword((prev) => !prev)
	}
	const resetInfo = () => {
		setName('')
		setPassword('')
		setConfirm_password('')
		setEmail('')
		setWard(2)
		setPhone('')
		setTitle('')
	}

	const handleAddEmployee = async () => {
		const employee_info: EmployeeAddInfo = {
			name,
			position: title,
			phone,
			ward_id: ward,
			email,
			password,
			confirm_password,
		}
		console.log(employee_info)
		if (!name || !title || !phone || !ward || !email || !password || !confirm_password) {
			toast.error('Vui lòng điền đủ các trường')
			return
		}
		try {
			const response = await toast.promise(client.post('/v1/manager/staff', employee_info), {
				pending: 'Đang cập nhật thông tin',
				success: 'Cập nhật thành công'
			})
			console.log(response)
			resetInfo()
			handleClose()
			refreshPage()
		} catch (error) {
			toast.error("Có lỗi xảy ra")
		}
	}

	return (
		<div>
			<Button onClick={handleOpen} sx={{mr: 2}} variant='contained'>
				Thêm cán bộ
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<div className='flex flex-row mb-10'>
						<Typography
							id='modal-modal-title'
							variant='h5'
							component='h2'
							className='font-bold text-center flex-1'>
							Thêm cán bộ
						</Typography>
						<IconButton onClick={handleClose}>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
									fill='black'
								/>
							</svg>
						</IconButton>
					</div>
					<Typography
						id='modal-modal-title'
						variant='h6'
						component='h6'
						className='font-bold'>
						Thông tin cán bộ
					</Typography>
					<TextField
						id='name'
						name='name'
						label='Tên cán bộ'
						fullWidth
						placeholder='Nhập tên cán bộ'
						sx={{marginTop: 2}}
						value={name}
						onChange={(e) => setName(e.target.value)}
						required
						error={false}
						helperText={''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
					/>
					<TextField
						id='title'
						name='title'
						label='Chức vụ'
						fullWidth
						placeholder='Nhập chức vụ'
						sx={{marginTop: 2}}
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						required
						error={false}
						helperText={''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
					/>
					<TextField
						id='phone'
						name='phone'
						label='Số điện thoại'
						fullWidth
						placeholder='Nhập số điện thoại'
						sx={{marginTop: 2}}
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
						required
						// error={phone.length < 10 || phone[0] !== '0'}
						// helperText={phone.length < 10 ? 'Số điện thoại không đúng định dạng' : ''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
					/>
					<FormControl sx={{width: '100%', marginTop: 2, marginBottom: 4}}>
						<InputLabel id='demo-simple-select-autowidth-label'>
							Chọn đơn vị *
						</InputLabel>
						<Select
							labelId='demo-simple-select-autowidth-label'
							id='demo-simple-select-autowidth'
							value={ward}
							label='Chọn đơn vị *'
							onChange={(e) => {
								setWard(Number(e.target.value))
							}}
							MenuProps={{
								PaperProps: {
									sx: {
										maxHeight: 200,
									},
								},
							}}>
							{WardList[
								auth.user.level as keyof typeof WardList
							].map((item) => {
								if (item.value !== 1) return (
									<MenuItem key={item.value} value={item.value}>
										{item.name}
									</MenuItem>
								)
							})}
						</Select>
					</FormControl>

					<Typography
						id='modal-modal-title'
						variant='h6'
						component='h6'
						className='font-bold'>
						Thông tin đăng nhập
					</Typography>
					<TextField
						id='email'
						name='email'
						label='Email'
						fullWidth
						placeholder='Nhập email'
						sx={{marginTop: 2}}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						error={false}
						helperText={''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
					/>
					<TextField
						id='password'
						name='password'
						label='Mật khẩu'
						fullWidth
						placeholder='Nhập mật khẩu'
						sx={{marginTop: 2}}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						error={false}
						helperText={''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => handleClickShowPassword()}
										onMouseDown={() => {}}>
										{showPassword ? (
											<VisibilityIcon />
										) : (
											<VisibilityOffIcon />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TextField
						id='repassword'
						name='repassword'
						label='Nhập lại mật khẩu'
						fullWidth
						placeholder='Nhập lại mật khẩu'
						sx={{marginTop: 2}}
						value={confirm_password}
						onChange={(e) => setConfirm_password(e.target.value)}
						required
						error={password !== confirm_password}
						helperText={password !== confirm_password ? 'Mật khẩu không khớp' : ''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
						type={showConfirmPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => handleClickShowPassword(true)}
										onMouseDown={() => {}}>
										{showConfirmPassword ? (
											<VisibilityIcon />
										) : (
											<VisibilityOffIcon />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<div className='flex flex-row mt-7 justify-end'>
						<Button
							onClick={handleClose}
							sx={{mr: '16px'}}
							variant='text'
							color='error'>
							Hủy
						</Button>
						<Button
							onClick={handleAddEmployee}
							sx={{}}
							variant='outlined'
							color='primary'>
							Xác nhận
						</Button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}

export default EmployeeAddModal
