import {
	TableContainer,
	Table,
	TableRow as Row,
	TableCell as Cell,
	Paper,
	Input as I,
	Select,
	MenuItem,
	styled,
} from '@mui/material'
import {Account} from '../../hooks/useAccount'
import {ChangeEvent} from 'react'

const TableCell = styled(Cell)`
	font-size: 1.1rem;
`

const TableRow = styled(Row)``

const Input = styled(I)`
	width: 100%;
	padding: 0.1rem 0.5rem;
`

export interface InfoTableProps {
	account: Account
	enableEditing?: boolean
}

function InfoTable({account, enableEditing = false}: InfoTableProps) {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableRow>
					<TableCell>Họ và tên</TableCell>
					<TableCell>
						{enableEditing ? (
							<Input
								value={account.fullName}
								onChange={(e) =>
									account.setFullName?.(e.target.value)
								}
							/>
						) : (
							account.fullName
						)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Chức vụ</TableCell>
					<TableCell>{enableEditing ? (
							<Input
								value={account.position}
								onChange={(e) =>
									account.setPosition?.(e.target.value)
								}
								disabled
							/>
						) : (
							account.position
						)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Email</TableCell>
					<TableCell>{account.email}</TableCell>
				</TableRow>
				{/* <TableRow>
					<TableCell>Giới tính</TableCell>
					<TableCell>
						{enableEditing ? (
							<Select
								value={account.gender}
								onChange={(e) =>
									account.setGender?.(e.target.value)
								}>
								<MenuItem value='0'>Nữ</MenuItem>
								<MenuItem value='1'>Nam</MenuItem>
							</Select>
						) : account.gender === '0' ? (
							'Nữ'
						) : (
							'Nam'
						)}
					</TableCell>
				</TableRow> */}
				{/* <TableRow>
					<TableCell>Ngày sinh</TableCell>
					<TableCell>
						{enableEditing ? (
							<Input
								value={account.birthDate}
								onChange={(e) =>
									account.setBirthDate?.(e.target.value)
								}
							/>
						) : (
							account.birthDate
						)}
					</TableCell>
				</TableRow> */}
				<TableRow>
					<TableCell>Số điện thoại</TableCell>
					<TableCell>
						{enableEditing ? (
							<Input
								value={account.phoneNumber}
								onChange={(e) =>
									account.setPhoneNumber?.(e.target.value)
								}
							/>
						) : (
							account.phoneNumber
						)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Đơn vị</TableCell>
					<TableCell>
						{/* {enableEditing ? (
							<Input
								value={account.unit}
								onChange={(e) =>
									account.setUnit?.(e.target.value)
								}
							/>
						) : (
							account.unit
						)} */}
						{account.unit}
					</TableCell>
				</TableRow>
			</Table>
		</TableContainer>
	)
}

export default InfoTable

interface PasswordTableProps {
	id: string
	password: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	enableEditing?: boolean
}

export function PasswordTable({
	id,
	password,
	onChange,
	enableEditing,
}: PasswordTableProps) {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableRow>
					<TableCell>Password</TableCell>
					<TableCell>
						<Input
							id={id}
							sx={{
								letterSpacing: '0.25rem',
							}}
							value={password}
							onChange={onChange}
							type='password'
							disabled={!enableEditing}
						/>
					</TableCell>
				</TableRow>
			</Table>
		</TableContainer>
	)
}
