import {
	Box,
	CircularProgress,
	IconButton,
	Link,
	Modal,
	Stack,
	Typography,
} from '@mui/material'
import React, {useState} from 'react'
import eyeIcon from '../../../assets/icons/eye.svg'
import { linkFormatter } from '../../../helpers/formatters/linkFormatter'

export interface SeeLinkModalProps {
	linkItems: Array<string>
	onOpen?: (links?: string[]) => void
	isLoading?: boolean
}

export interface LinkItem {
	work_link_id: number
	work_id: number
	link: string
	created_by: {}
}

const SeeLinkModal = ({linkItems, onOpen, isLoading = false}: SeeLinkModalProps) => {
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}
	const handleOpen = () => {
		setOpen(true)
		onOpen?.(linkItems)
	}

	const standardizeLink = (link: string): string => {
		if (link.includes('https://') || link.includes('http://')) return link
		else {
			return `//${link}`
		}
	}

	return (
		<Stack>
			<div className='w-full flex flex-col items-center'>
				<img
					className='cursor-pointer'
					src={eyeIcon}
					alt='See more'
					onClick={handleOpen}
				/>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={{width: '600px'}} className='box-modal-style'>
					<div className='flex flex-row mb-6'>
						<Typography
							id='modal-modal-title'
							variant='h5'
							component='h2'
							className='font-bold text-center flex-1'>
							Nộp link
						</Typography>
						<IconButton onClick={handleClose}>
							<svg
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
									fill='black'
								/>
							</svg>
						</IconButton>
					</div>
					<Stack>
						{isLoading ? (
							<div className="w-full flex flex-col items-center justify-center">
								<CircularProgress />
							</div>
						) : linkItems.length ? (
							linkItems.map((linkItem, index) => (
								<Stack className='mb-6' key={index}>
									<Typography>Link {index + 1}</Typography>
									<Link
										href={standardizeLink(linkItem)}
										target='_blank'
										rel='noopener noreferrer'
										className='overflow-hidden line-clamp-1'>
										{linkItem}
									</Link>
								</Stack>
							))
						) : (
							<Typography className='mx-auto'>
								Không có link nào.
							</Typography>
						)}
					</Stack>
				</Box>
			</Modal>
		</Stack>
	)
}

export default SeeLinkModal
