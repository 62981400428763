export function formatBytes(bytes: number): string {
	// Define the units and their corresponding powers of 1024
	const units = ['B', 'KB', 'MB', 'GB', 'TB']
	const powers = [0, 1, 2, 3, 4]

	// Find the largest power of 1024 that is smaller than or equal to the bytes
	let index = powers.length - 1
	while (index > 0 && Math.pow(1024, index) > bytes) {
		index--
	}

	// Divide the bytes by the corresponding power of 1024 and round to two decimals
	const value = Math.round((bytes / Math.pow(1024, index)) * 100) / 100

	// Return the formatted string with the unit
	return `${value} ${units[index]}`
}
