import {ChangeEventHandler, ChangeEvent, useId, useState, useEffect} from 'react'
import {Modal, Link as L, styled, OutlinedInput, Button} from '@mui/material'
import shortId from 'shortid'
import closeIcon from '../../../assets/icons/close.svg'

const Link = styled(L)`
	cursor: pointer;
`
const CloseButton = styled(Button)`
	height: 24px;
	min-width: 24px;
	padding: 0px;
	position: absolute;
	top: 0rem;
	right: 0rem;
	display: flex;
	flex-direction: column;
	align-items: end;
`

export interface DataLink {
	id: string
	value: string
}

interface SubmitLinkModalProps {
	handleSubmit?: (links: DataLink[]) => void
}

function SubmitLinkModal({handleSubmit}: SubmitLinkModalProps) {
	const [isOpen, setIsOpen] = useState(false)
	const [links, setLinks] = useState<DataLink[]>([])
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		const link = {id: shortId.generate(), value: ''}
		setLinks([link])
	}, [])

	const handleSubmitButtonClick = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	const handleAcceptButtonClick = async () => {
		// TODO: Send Links and Notify
		setIsSubmitting(true)
		try {
			if (handleSubmit) {
				await handleSubmit(links)
			}
			// Notify send success

			setLinks([{id: shortId.generate(), value: ''}])
			setIsOpen(false)
			setIsSubmitting(false)
		} catch (error) {
			// TODO: Notify send fail
			setIsSubmitting(false)
		}
	}

	const onChangeLink = (id: string, e: ChangeEvent<HTMLInputElement>) => {
		const link = e.target.value
		const newLinks = links.map((value) => {
			if (value.id !== id) {
				return value
			}

			return {
				...value,
				value: link,
			}
		})

		setLinks(newLinks)
	}

	const handleAddLink = () => {
		const newLink = {
			id: shortId.generate(),
			value: '',
		}
		const newLinks = [...links, newLink]
		setLinks(newLinks)
	}

	return (
		<>
			<Link onClick={handleSubmitButtonClick}>Nộp</Link>
			<Modal
				open={isOpen}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<div className='w-[450px] absolute p-8 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] bg-white flex flex-col items-center gap-8'>
					<h3 className='text-2xl font-bold'>Nộp Link</h3>
					<div className='w-full flex flex-col gap-4'>
						{links &&
							links.map((link, index) => (
								<InputLink
									disabled={isSubmitting}
									key={link.id}
									number={index + 1}
									link={link.value}
									onChange={(e) => {
										onChangeLink(link.id, e)
									}}
								/>
							))}
					</div>
					<Link onClick={handleAddLink}>+ Thêm link nộp</Link>
					<div className='w-full flex flex-row justify-end gap-2'>
						<Button
							variant='contained'
							color='error'
							onClick={handleClose}>
							Hủy
						</Button>
						<Button
							variant='contained'
							onClick={handleAcceptButtonClick}>
							Xác nhận
						</Button>
					</div>
					<CloseButton onClick={handleClose}>
						<img src={closeIcon} />
					</CloseButton>
				</div>
			</Modal>
		</>
	)
}

export default SubmitLinkModal

interface InputLinkProps {
	disabled?: boolean
	number: number
	link: string
	onChange?: ChangeEventHandler<HTMLInputElement>
}

export function InputLink({disabled=false, number, link, onChange}: InputLinkProps) {
	const InputId = useId()

	return (
		<div className='flex flex-col gap-2'>
			<label htmlFor={InputId}>Link {number}</label>
			<OutlinedInput
				disabled={disabled}
				type='url'
				color='primary'
				value={link}
				onChange={onChange}
			/>
		</div>
	)
}
