import {useState, useEffect, useRef, SyntheticEvent} from 'react'
import {
	Stack,
	IconButton,
	Popper,
	Grow,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Divider,
	Typography,
} from '@mui/material'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

export interface ActionButtonProps {
	edit?: boolean
	deleteAble?: boolean
	editAction?: () => void
	deleteAction?: () => void
}

const ActionButton = ({
	edit = false,
	deleteAble = false,
	editAction,
	deleteAction,
}: ActionButtonProps) => {
	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: Event | SyntheticEvent) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return
		}

		setOpen(false)
	}

	const prevOpen = useRef(open)
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus()
		}

		prevOpen.current = open
	}, [open])

	return (
		<>
			<IconButton
				ref={anchorRef}
				id='composition-button'
				aria-controls={open ? 'composition-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup='true'
				onClick={handleToggle}>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M12 20.4C13.3255 20.4 14.4 19.3255 14.4 18C14.4 16.6745 13.3255 15.6 12 15.6C10.6745 15.6 9.59998 16.6745 9.59998 18C9.59998 19.3255 10.6745 20.4 12 20.4Z'
						fill='black'
					/>
					<path
						d='M12 14.4C13.3255 14.4 14.4 13.3255 14.4 12C14.4 10.6745 13.3255 9.59998 12 9.59998C10.6745 9.59998 9.59998 10.6745 9.59998 12C9.59998 13.3255 10.6745 14.4 12 14.4Z'
						fill='black'
					/>
					<path
						d='M12 8.39998C13.3255 8.39998 14.4 7.32546 14.4 5.99998C14.4 4.67449 13.3255 3.59998 12 3.59998C10.6745 3.59998 9.59998 4.67449 9.59998 5.99998C9.59998 7.32546 10.6745 8.39998 12 8.39998Z'
						fill='black'
					/>
				</svg>
			</IconButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				placement='left'
				transition
				disablePortal>
				{({TransitionProps, placement}) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'left'
									? 'right bottom'
									: 'right top',
						}}>
						<Stack
							sx={{
								width: '130px',
								backgroundColor: '#fff',
								padding: 0,
								boxShadow: '0.5px 0.5px 7px rgba(0,0,0,0.6)'
							}}
							className='rounded-lg'>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id='composition-menu'
									aria-labelledby='composition-button'>
									{edit && (
										<>
											<MenuItem
												onClick={editAction}
												sx={{
													width: '100%',
													justifyContent: 'center',
													':hover': {
														bgcolor: '#1976d2',
														color: '#fff',
													},
												}}>
												<EditOutlinedIcon />{' '}
												<Typography
													sx={{
														flex: 1,
														textAlign: 'center',
													}}>
													Chỉnh sửa
												</Typography>
											</MenuItem>
											<Divider variant='fullWidth' />
										</>
									)}

									{deleteAble && (
										<MenuItem
											onClick={deleteAction}
											sx={{
												width: '100%',
												justifyContent: 'center',
												':hover': {
													bgcolor: '#1976d2',
													color: '#fff',
												},
											}}>
											<DeleteOutlineRoundedIcon />{' '}
											<Typography
												sx={{
													flex: 1,
													textAlign: 'center',
												}}>
												Xóa
											</Typography>
										</MenuItem>
									)}
								</MenuList>
							</ClickAwayListener>
						</Stack>
					</Grow>
				)}
			</Popper>
		</>
	)
}

export default ActionButton
