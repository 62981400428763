import { ReactElement } from 'react'
import Sidebar from '../Sidebar'

interface DefaultLayoutProps {
	isCanBo: boolean
    children: ReactElement
}

function DefaultLayout({isCanBo, children}: DefaultLayoutProps) {
	return (
		<div className='App h-screen flex flex-row'
			onDragStart={e => e.preventDefault()}
		>
			<Sidebar isCanBo={isCanBo} />
            <div className="w-full h-full pl-[230px]">
				<div className="w-full h-full overflow-auto">
                	{children}
				</div>
            </div>
		</div>
	)
}

export default DefaultLayout
