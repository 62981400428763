import React, {useState, useEffect} from 'react'
import {
	Modal,
	Box,
	Stack,
	IconButton,
	Skeleton,
	Typography,
	Pagination,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {ModalProps} from '../../../interfaces/modal'
import CarbonCopyAlertCard from '../../CarbonCopyAlertCard'
import {toast} from 'react-toastify'
import useLoading from '../../../hooks/useLoading'
import client from '../../../services/axios'
import {IPagination} from '../../../interfaces/pagination'
import DisplayNotificationModal from '../DisplayNotificationModal'
import { NotificationHistoryData } from '../../../interfaces/notification'
import { useAuth } from '../../Auth/auth'

// export interface NotificationDetail {
// 	notification_id: number
// 	header: string
// 	content: string
// 	created_by: number
// 	name: string
// 	employees: Array<string>
// 	created_at: string
// 	files?: AttachmentInfo[]
// }


const initialHistory = {
	per_page: 7,
	current_page: 1,
	total_pages: 0,
	total_items: 0,
	items: [],
}

const NotificationHistoryModal = ({open, handleClose = () => {}}: ModalProps) => {
	// TODO: API call

	const [current, setCurrent] = useState<NotificationHistoryData | null>(null)
	const [detailOpen, setDetailOpen] = useState(false)
	const [data, setData] = useState<IPagination<NotificationHistoryData>>(initialHistory)
	const [refetch, setRefetch] = useState(false)

	const loader = useLoading()
	const auth = useAuth()

	const openAlertDetailModal = (item: NotificationHistoryData): void => {
		setCurrent(item)
		setDetailOpen(true)
	}
	const closeDetailModal = (): void => {
		setCurrent(null)
		setDetailOpen(false)
	}
	const closeAll = (): void => {
		setDetailOpen(false)
		handleClose()
	}

	const fetchNotificationHistory = async () => {
		try {
			loader.setIsLoading(true)
			const response = await client.get('/v1/manager/notifications_history', {
				params: {
					per_page: data.per_page,
					current_page: data.current_page,
				},
			})
			setData(response.data.data)
			console.log(response.data.data)
			loader.setIsLoading(false)
			loader.setErr(false)
		} catch (error) {
			toast.error('Có lỗi xảy ra')
			loader.setIsLoading(false)
			loader.setErr(true)
		}
	}

	useEffect(() => {
		if (open) {
			fetchNotificationHistory()
		}
	}, [open, refetch])

	return (
		<>
			<Modal open={open} onClose={handleClose}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 900,
						height: 600,
						backgroundColor: 'white',
						border: '3px solid #1976d2',
						boxShadow: 24,
						padding: 4,
					}}>
					<div className='flex flex-row justify-center items-baseline'>
						<h1 className='text-3xl font-bold mb-6 text-center flex-1'>
							Thông báo đã gửi
						</h1>
						<IconButton
							children={<CloseIcon />}
							onClick={handleClose}
						/>
					</div>
					<Stack className='pt-3' direction='column' height='90%'>
						<Stack direction='column' gap={1}>
							{loader.isLoading ? (
								<>
									<Skeleton
										variant='rectangular'
										height={48}
									/>
									<Skeleton
										variant='rectangular'
										height={48}
									/>
									<Skeleton
										variant='rectangular'
										height={48}
									/>
									<Skeleton
										variant='rectangular'
										height={48}
									/>
									<Skeleton
										variant='rectangular'
										height={48}
									/>
									<Skeleton
										variant='rectangular'
										height={48}
									/>
									<Skeleton
										variant='rectangular'
										height={48}
									/>
								</>
							) : loader.err ? (
								<Typography align='center' variant='h5'>
									Có lỗi xảy ra
								</Typography>
							) : data.items.length === 0 ? (
								<Typography align='center' variant='h5'>
									Không tìm thấy kết quả nào
								</Typography>
							) : (
								data.items.map((item) => (
									<CarbonCopyAlertCard
										receivers={item.notification_employee.map(value => value.employee.name)}
										title={item.header}
										time={item.created_at}
										onClick={() =>
											openAlertDetailModal(item)
										}
									/>
								))
							)}
						</Stack>
						<Stack
							direction='row'
							justifyContent='center'
							alignItems='flex-end'
							flex={1}>
							<Pagination
								color='primary'
								size='medium'
								count={data.total_pages}
								page={data.current_page}
								onChange={(event: React.ChangeEvent<unknown>, value: number) => {
									setData({...data, current_page: value})
									console.log(data)
									setRefetch(!refetch)
								}}
								sx={{
									mb: '24px'
								}}
							/>
						</Stack>
					</Stack>
				</Box>
			</Modal>
			{current ? (
				<DisplayNotificationModal
					open={detailOpen}
					title={current.header}
					sender={auth.user.name}
					receivers={current.notification_employee.map(e => e.employee.name)}
					createdAt={new Date(current.created_at)}
					content={current.content}
					attachments={current.notification_link}
					revertable
					handleBack={closeDetailModal}
					onClose={closeAll}
				/>
			) : (
				<></>
			)}
		</>
	)
}

export default NotificationHistoryModal
