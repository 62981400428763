import {useState} from 'react'
import {EmployeeInfo} from '../interfaces/employees'

export interface NotificationFormData {
	title: string
	content: string
	wardId: number
	receivers: EmployeeInfo[]
	files: File[]
}

function useNotificationForm() {
	const [title, setTitle] = useState('')
	const [content, setContent] = useState('')
	const [receivers, setReceivers] = useState<EmployeeInfo[]>([])
	const [wardId, setWardId] = useState(0)
	const [files, setFiles] = useState<File[]>([]);

	const addReceiver = (receiver: EmployeeInfo) => {
        if (receivers.findIndex(r => r.employee_id === receiver.employee_id) === -1)
		    setReceivers([...receivers, receiver])
	}

	const removeReceiver = (receiver: EmployeeInfo) => {
		setReceivers(receivers.filter((r) => r.employee_id !== receiver.employee_id))
	}

    const clearReceivers = () => {
        setReceivers([]);
    }

	const addFile = (file: File) => {
		setFiles(prev => [...prev, file])
	}

	const removeFile = (file: File) => {
        setFiles(files.filter((f) => f.name!== file.name || f.lastModified!== file.lastModified))
    }

	const reset = () => {
		setTitle('')
		setContent('')
		setWardId(0)
		clearReceivers()
		setFiles([])
	}

	return {
		title,
		setTitle,
		content,
		setContent,
		receivers,
		setReceivers,
		wardId,
		setWardId,
		files,
		addFile,
		removeFile,
        addReceiver,
        removeReceiver,
        clearReceivers,
		reset
	}
}

export default useNotificationForm
