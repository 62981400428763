import {useState, useEffect, useRef} from 'react'
import {Modal, Box, IconButton, Divider, Link} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dateFormatter from '../../../helpers/formatters/dateFormatter'
import {formatBytes} from '../../../helpers/formatters/binaryUnitFormat'
import closeIcon from '../../../assets/icons/close.svg'
import { IFile } from '../../../interfaces/notification';
import clsx from 'clsx'
import style from '../../../styles/notification.module.scss'
import { downloadFile } from '../../../helpers/fileDownloader';
import { useAuth } from '../../Auth/auth';

interface DisplayNotificationModalProps {
	open: boolean
	onClose?: () => void
	sender?: string
	receivers?: string[]
	createdAt: Date
	title?: string
	content?: string
	attachments?: IFile[]
	revertable?: boolean // Enable back arrow navigation
	handleBack?: () => void // Handle back arrow navigation
}

function DisplayNotificationModal({
	open,
	onClose,
	title,
	content,
	attachments,
	sender,
	receivers,
	createdAt,
	revertable = false,
	handleBack = () => {},
}: DisplayNotificationModalProps) {
	const handleClose = onClose
	const auth = useAuth()

	const renderContent = (content: string | undefined) => {
		if (!content) {
			return {__html: 'Không có nội dung'}
		} else if (content.includes('<script>')) {
			return {__html: 'Phát hiện nội dung có chứa mã độc'}
		} else {
			return {__html: content}
		}
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1.5rem',
					height: '96%',
					width: '90%',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: 4,
					overflow: 'auto',
				}}>
				<div className='flex flex-row justify-between font-default'>
					{
						revertable ? <IconButton children={<ArrowBackIcon />} onClick={handleBack} sx={{mr: '8px'}} /> : <></>
					}
					<h2 className='font-bold text-3xl flex-1'>{title}</h2>
					<button onClick={onClose}>
						<img src={closeIcon} alt='close' />
					</button>
				</div>

				<div className='flex flex-row justify-between gap-2 font-default'>
					<div className='flex flex-col'>
						<p className='text-2xl font-bold'>{sender}</p>
						<p className='text-sm'>
							gửi đến {receivers && receivers.join(', ')}
						</p>
					</div>
					<div className='text-[#5E5E5E]'>
						{dateFormatter(createdAt)['HH:MM dd/mm/yyyy']}
					</div>
				</div>
				<div className="content-wrapper ql-container ql-snow">
					<div
						className={clsx('w-full content-wrapper ql-editor overflow-hidden', style['content-wrapper'])}
						dangerouslySetInnerHTML={renderContent(content)}
					/>
				</div>
				<Divider />
				<h3 className='text-2xl font-bold font-default'>Tệp đính kèm</h3>
				<div className='w-[400px] flex flex-col gap-2 font-default'>
					{attachments &&
						attachments.length > 0 &&
						attachments.map((value) => (
							<div key={value.notification_link_id} className='bg-gray-200 p-2'>
								<Link href={value.link_content} target='_blank' sx={{fontWeight: 'bold', textDecoration: 'none'}} onClick={e => downloadFile(e, value.link_content, value.name, auth.user.accessToken)}>
									{value.name}{' '}
									<span className='text-black no-underline'>
										({formatBytes(value.size)})
									</span>
								</Link>
							</div>
						))}
				</div>
			</Box>
		</Modal>
	)
}

export default DisplayNotificationModal
