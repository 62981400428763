import React, {useEffect, useState} from 'react'
import NotificationItem from '../../components/NotificationItem'
import {AlertData, NotificationData, AlertType} from '../../interfaces/notification'
import SendNotificationModal from '../../components/Modal/SendNotificationModal'
import {
	IconButton,
	Pagination,
	Skeleton,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material'
import historyIcon from '../../assets/icons/history.svg'
import NotificationHistoryModal from '../../components/Modal/NotificationHistoryModal'
import {defaultNotifications} from '../NotificationPage_CB'
import {IPagination} from '../../interfaces/pagination'
import client from '../../services/axios'
import useLoading from '../../hooks/useLoading'
import {toast} from 'react-toastify'
import CarbonCopyAlertCard from '../../components/CarbonCopyAlertCard'
import {RedAlertCircle} from '../../components/RedAlertCircle'
import {useUnseenNotification} from '../../components/UnseenNotificationProvider'

const ManagerNotificationPage = () => {
	const [notifications, setNotifications] =
		useState<IPagination<AlertData & NotificationData>>(defaultNotifications)
	const [historyOpen, setHistoryOpen] = useState(false)
	const [refreshKey, setRefreshKey] = useState(false)
	const [currentTab, setCurrentTab] = useState(0)
	const loader = useLoading()
	const unseen = useUnseenNotification()

	const refreshPage = (): void => {
		setRefreshKey(!refreshKey)
	}
	const onChangeTab = (event: React.SyntheticEvent, value: number) => {
		setCurrentTab(value)
		setNotifications({...notifications, items: [], current_page: 1})
		refreshPage()
	}
	const onChangePage = (event: React.ChangeEvent<unknown>, value: number): void => {
		setNotifications({
			...notifications,
			current_page: value,
		})
		refreshPage()
	}
	const openHistoryModal = (): void => {
		setHistoryOpen(true)
	}
	const closeHistoryModal = (): void => {
		setHistoryOpen(false)
	}
	const fetchNotification = async () => {
		loader.setIsLoading(true)
		try {
			const res = await client.get('/v1/manager/alert', {
				params: {
					per_page: 8,
					current_page: notifications.current_page,
				},
			})
			const data = res.data.data
			setNotifications(data)
			loader.setIsLoading(false)
			loader.setErr(false)
		} catch (error) {
			toast.error('Lỗi! Không thể tải được thông báo')
			loader.setErr(true)
			loader.setIsLoading(false)
		}
	}
	const fetchReceivedNotifications = async () => {
		loader.setIsLoading(true)
		try {
			const res = await client.get('/v1/employee/notifications', {
				params: {
					per_page: 8,
					current_page: notifications.current_page,
				},
			})
			const data = res.data.data
			setNotifications(data)
			loader.setIsLoading(false)
			loader.setErr(false)
		} catch (error) {
			toast.error('Lỗi! Không thể tải được thông báo')
			loader.setErr(true)
			loader.setIsLoading(false)
		}
	}

	// Mark as seen for Alert
	const markSeenAlert = async () => {
		try {
			const res = await client.put('/v1/manager/alert_seen', {total_unseen_alert_seen_id_list: unseen.total_unseen_alert_seen_id_list})
			unseen.refresh()
		} catch (error) {
			
		}
	}

	// Mark as seen for Notification
	const markSeenNotification = async () => {
		try {
			console.log(unseen.total_unseen_notification_id_list);
			
			const res = await client.put('/v1/employee/notifications_seen', {total_unseen_notification_id_list: unseen.total_unseen_notification_id_list})
			unseen.refresh()
		} catch (error) {
			
		}
	}

	useEffect(() => {
		if (currentTab === 0) {
			fetchNotification()
		} else {
			fetchReceivedNotifications()
		}
	}, [refreshKey])

	useEffect(() => {
		if (
			!loader.isLoading &&
			currentTab === 0 &&
			unseen.total_unseen_alert_number > 0
		) {
			markSeenAlert()
		} else if (
			!loader.isLoading &&
			currentTab === 1 &&
			unseen.total_unseen_notification_number > 0
		) {
			markSeenNotification()
		}
	}, [
		loader.isLoading,
		currentTab,
		unseen.total_unseen_alert_number,
		unseen.total_unseen_notification_number,
	])

	return (
		<>
			<div className='w-full h-screen flex flex-col text-left gap-4 p-4 overflow-auto font-default justify-between'>
				<div className='w-full flex flex-row items-center justify-between'>
					<h1 className='text-3xl font-bold mb-6'>Thông báo</h1>
					<div className='flex flex-row gap-6'>
						<IconButton onClick={openHistoryModal}>
							<img src={historyIcon} alt='history' />
						</IconButton>
						<SendNotificationModal />
					</div>
				</div>

				<Stack
					direction='column'
					gap={2}
					marginTop={4}
					justifyContent='space-evenly'>
					<Stack direction='row'>
						<Tabs
							value={currentTab}
							onChange={onChangeTab}
							aria-label='notification-type-select-tab'>
							<Tab
								label='Cơ quan'
								value={0}
								icon={
									<RedAlertCircle
										text={unseen.total_unseen_alert_number}
									/>
								}
								iconPosition='end'
							/>
							<Tab
								label='Người dùng'
								value={1}
								icon={
									<RedAlertCircle
										text={
											unseen.total_unseen_notification_number
										}
									/>
								}
								iconPosition='end'
							/>
						</Tabs>
					</Stack>
					{loader.isLoading ? (
						<>
							<Skeleton variant='rectangular' height={'56px'} />
							<Skeleton variant='rectangular' height={'56px'} />
							<Skeleton variant='rectangular' height={'56px'} />
							<Skeleton variant='rectangular' height={'56px'} />
							<Skeleton variant='rectangular' height={'56px'} />
							<Skeleton variant='rectangular' height={'56px'} />
							<Skeleton variant='rectangular' height={'56px'} />
							<Skeleton variant='rectangular' height={'56px'} />
						</>
					) : loader.err ? (
						<Typography align='center' variant='h5'>
							Có lỗi xảy ra
						</Typography>
					) : notifications.items.length > 0 ? (
						notifications.items.map((item) => {
							if (currentTab === 0)
								return (
									<NotificationItem
										key={item.alert_id}
										type={item.state}
										createdAt={new Date(item.created_at)}
										unit={item.ward.name}
										assignees={item.alert_employee.map(
											(value: any) => value.employee.name
										)}
										taskName={item.work.name}
										reviewer={item.work.created_by.name}
									/>
								)
							else {
								return (
									<NotificationItem
										key={item.notification_id}
										type={AlertType.receiving}
										createdAt={new Date(item.created_at)}
										unit={item.ward.name}
										assignees={[]}
										title={item.header}
										reviewer={item.creator.name}
										content={item.content}
										attachments={item.notification_link}
									/>
								)
							}
						})
					) : (
						<Typography align='center' variant='h5'>
							Không có dữ liệu
						</Typography>
					)}
				</Stack>

				<Stack
					direction='row'
					justifyContent='center'
					alignItems='flex-end'
					sx={{flex: 1, justifySelf: 'flex-end'}}>
					<Pagination
						color='primary'
						size='large'
						count={notifications.total_pages}
						page={notifications.current_page}
						onChange={onChangePage}
						sx={{
							mb: '56px',
						}}
					/>
				</Stack>
			</div>
			<NotificationHistoryModal
				open={historyOpen}
				handleClose={closeHistoryModal}
			/>
		</>
	)
}

export default ManagerNotificationPage
