import {useRef, useMemo, useCallback, useState, useEffect, DragEvent} from 'react'
import ReactQuill from 'react-quill'
import clsx from 'clsx'
import { svgUndo, svgRedo, svgAttachment } from './svg'

const icons = ReactQuill.Quill.import('ui/icons')

icons['undo'] = svgUndo

icons['redo'] = svgRedo

icons['attachment'] = svgAttachment

interface TextEditorProps {
	placeholder?: string
	value?: string
	onChange?: (value: string) => void
	onDropFiles?: (files: FileList) => void
}

function TextEditor({placeholder, value, onChange, onDropFiles}: TextEditorProps) {
	const [isDragging, setIsDragging] = useState(false)
	const ref = useRef<any>(null)
	const attachmentInput = useRef<HTMLInputElement>()

	useEffect(() => {
		const input = document.createElement('input')
		input.type = 'file'
		input.onchange = () => {
			const files = input.files as FileList
            if (onDropFiles && files.length > 0) {
                onDropFiles(files)
            }
        }

		attachmentInput.current = input

		return () => {
            input.remove()
        }
	}, [onDropFiles])

	const handleUndo = useCallback(() => {
		if (ref.current === null) {
			return
		}
		const editor = ref.current.getEditor()
		return editor.history.undo()
	}, [])

	const handleRedo = useCallback(() => {
		if (ref.current === null) {
			return
		}
		const editor = ref.current.getEditor()
		return editor.history.redo()
	}, [])

	const handleAttachment = useCallback(() => {
		attachmentInput.current?.click()
	}, [])

	const EDITOR_MODULES = useMemo(
		() => ({
			history: {
				delay: 1000,
				maxStack: 100,
				userOnly: false,
			},
			toolbar: {
				container: [
					['undo', 'redo'],
					[{size: ['small', false, 'large', 'huge']}],
					[{font: []}],
					['bold', 'italic', 'underline', 'strike'],
					[{color: []}, {background: []}],
					['blockquote', 'code-block'],
					[{header: 1}, {header: 2}],
					[{list: 'ordered'}, {list: 'bullet'}],
					[{script: 'sub'}, {script: 'super'}],
					[{indent: '-1'}, {indent: '+1'}],
					[{direction: 'rtl'}],
					[{align: []}],
					['link', 'attachment'],
					['clean'],
				],
				handlers: {
					undo: handleUndo,
					redo: handleRedo,
					attachment: handleAttachment
				},
			},
		}),
		[]
	)

	const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsDragging(true)
	}

	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsDragging(false)
	}

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsDragging(false)
		const files = e.dataTransfer.files
		if (files.length > 0 && onDropFiles) {
			onDropFiles(files)
		}
	}

	return (
		<div
			draggable
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}>
			<ReactQuill
				ref={ref}
				theme='snow'
				className={clsx('h-[200px] flex flex-col', {
					'outline outline-blue-500 outline-2': isDragging,
				})}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				modules={EDITOR_MODULES}
			/>
		</div>
	)
}

export default TextEditor
