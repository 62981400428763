import React, { useEffect, useState } from 'react'
import {EmployeesTable} from '../../components/EmployeesTable'
import {EmployeeWithWardsInfo} from '../../interfaces/employees'
import {Grid, Pagination, Stack, Typography} from '@mui/material'
import EmployeeAddModal from '../../components/Modal/EmployeeAddModal'
import useLoading from '../../hooks/useLoading'
import client from '../../services/axios'
import { IPagination } from '../../interfaces/pagination'

const EmployeeManagement = () => {
	const [employees, setEmployees] = useState<Array<EmployeeWithWardsInfo>>([])
	const [employeeData, setEmployeeData] = useState<IPagination<EmployeeWithWardsInfo>>({
		per_page: 6,
		current_page: 1,
		total_items: 0,
		total_pages: 0,
		items: [],
	})

	const loader = useLoading()
	const [refreshKey, setRefreshKey] = useState(false)
	const refreshPage = () => {
		setRefreshKey(!refreshKey)
	}

	const fetchEmployees = async () => {
		setEmployeeData({...employeeData, items: [], total_pages: 0})
		loader.setIsLoading(true)
		try {
			const response = await client.get('/v1/manager/available_employees', {
				params: {
					per_page: employeeData.per_page,
					current_page: employeeData.current_page,
					allEmployeeOfWard: 0
				}
			})
			console.log('Employees: ',response.data.data)
			setEmployeeData(response.data.data)
			loader.setIsLoading(false)
		} catch (error) {
			console.log('Fetch error: ', error)
			loader.setErr(true)
			loader.setIsLoading(false)
		}
	}
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setEmployeeData({...employeeData, current_page: value})
		refreshPage()
	}

	useEffect(() => {
		fetchEmployees()
	}, [refreshKey])

	// console.log('Employee management: ', employees)

	return (
		<div className='w-full flex flex-col p-8 items-start gap-4'>
			<div className='w-full flex flex-row justify-between items-center'>
				<Stack>
					<h1 className='text-4xl font-bold mb-8'>Quản lí cán bộ</h1>
				</Stack>
				<div className='flex flex-row'>
					<EmployeeAddModal refreshPage={refreshPage} />
				</div>
			</div>
			<h2 className='text-3xl font-bold'>Danh sách cán bộ</h2>
			<EmployeesTable employees={employeeData.items} refreshPage={refreshPage} />
			<Grid item xs={12} className='self-center'>
					{employeeData.total_pages !== 0 ? (
						<Stack
							spacing={2}
							className='mt-6 flex flex-col items-center'>
							<Typography>Trang: {employeeData.current_page}</Typography>
							<Pagination
								count={employeeData.total_pages}
								page={employeeData.current_page}
								onChange={handleChange}
								color='primary'
							/>
						</Stack>
					) : <></>}
				</Grid>
		</div>
	)
}

export default EmployeeManagement
