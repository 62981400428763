import {
	Modal,
	Box,
	Typography,
	Button,
	TextField,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {ModalProps} from '../../../interfaces/modal'
import { EmployeeWithWardsInfo } from '../../../interfaces/employees'
import { toast } from 'react-toastify'
import client from '../../../services/axios'
import { WardList } from '../../../pages/TaskAssignManagementPage'
import { useAuth } from '../../Auth/auth'

export interface EmployeeEditModalProps extends ModalProps {
	original: EmployeeWithWardsInfo
	refreshPage: () => void
}

const EmployeeEditModal = ({original, open, handleClose, refreshPage}: EmployeeEditModalProps) => {
	const [name, setName] = useState(original.name && original.name)
	const [title, setTitle] = useState(original.position)
	const [phone, setPhone] = useState(original.phone)
	// const [ward, setWard] = useState(original.ward.ward_id ? original.ward.ward_id : 2)
	const auth = useAuth()

	useEffect(() => {
		setName(original.name)
		setPhone(original.phone)
		setTitle(original.position)
		// setWard(original.ward.ward_id)
	}, [open])

	const handleEditEmployee = async () => {
		if (!name && !phone && !title) {
			toast.warning('Vui lòng thay đổi ít nhất 1 trường')
			return 
		}
		try {
			const response = await toast.promise(client.put(`/v1/manager/staff/${original.employee_id}`, {
				name,
				position: title,
				phone
			}, {
				params: {
					staff_id: original.employee_id
				}
			}), {
				pending: 'Đang chỉnh sửa thông tin cán bộ',
				success: 'Chỉnh sửa thành công'
			})
			handleClose && handleClose()
			refreshPage()

		} catch (error) {
			toast.error('Có lỗi xảy ra')
		}
	}
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'>
			<Box className='box-modal-style' sx={{width: 700, transform: 'translate(-50%, -50%) scale(0.95)'}}>
				<div className='flex flex-row mb-10'>
					<Typography
						id='modal-modal-title'
						variant='h5'
						component='h2'
						className='font-bold text-center flex-1'>
						Chỉnh sửa thông tin cán bộ
					</Typography>
					<IconButton onClick={handleClose}>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
								fill='black'
							/>
						</svg>
					</IconButton>
				</div>
				<Typography
					id='modal-modal-title'
					variant='h6'
					component='h6'
					className='font-bold'>
					Thông tin cán bộ
				</Typography>
				<TextField
						id='name'
						name='name'
						label='Tên cán bộ'
						fullWidth
						placeholder='Nhập tên cán bộ'
						sx={{marginTop: 2}}
						value={name}
						onChange={(e) => setName(e.target.value)}
						required
						error={false}
						helperText={''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
					/>
					<TextField
						id='title'
						name='title'
						label='Chức vụ'
						fullWidth
						placeholder='Nhập chức vụ'
						sx={{marginTop: 2}}
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						required
						error={false}
						helperText={''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
					/>
					<TextField
						id='phone'
						name='phone'
						label='Số điện thoại'
						fullWidth
						placeholder='Nhập số điện thoại'
						sx={{marginTop: 2}}
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
						required
						// error={phone.length < 10 || phone[0] !== '0'}
						// helperText={phone.length < 10 ? 'Số điện thoại không đúng định dạng' : ''}
						inputProps={{
							onBlur: () => {},
							onFocus: () => {},
						}}
					/>
					{/* <FormControl sx={{width: '100%', marginTop: 2, marginBottom: 4}}>
						<InputLabel id='demo-simple-select-autowidth-label'>
							Chọn đơn vị *
						</InputLabel>
						<Select
							labelId='demo-simple-select-autowidth-label'
							id='demo-simple-select-autowidth'
							value={ward}
							label='Chọn đơn vị *'
							onChange={(e) => {
								setWard(Number(e.target.value))
							}}
							MenuProps={{
								PaperProps: {
									sx: {
										maxHeight: 200,
									},
								},
							}}>
							{WardList[
								auth.user.level as keyof typeof WardList
							].map((item) => {
								if (item.value !== 1) return (
									<MenuItem key={item.value} value={item.value}>
										{item.name}
									</MenuItem>
								)
							})}
						</Select>
					</FormControl> */}

				<div className='flex flex-row mt-7 justify-end'>
					<Button
						onClick={handleClose}
						sx={{mr: '16px'}}
						variant='text'
						color='error'>
						Hủy
					</Button>
					<Button
						onClick={handleEditEmployee}
						sx={{}}
						variant='outlined'
						color='primary'>
						Xác nhận
					</Button>
				</div>
			</Box>
		</Modal>
	)
}

export default EmployeeEditModal
