import axios from 'axios'

const client = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 10000,
})

client.interceptors.request.use((config) => {
	const accessToken = localStorage.getItem('accessToken')
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`
	}
	return config
})

let isRefreshing = false
let refreshSubscribers: ((access_token: string) => void)[] = []

client.interceptors.response.use(
	(res) => res,
	(error) => {
		const originalRequest = error.config
		const refreshToken = localStorage.getItem('refreshToken')
		if (error.response.status === 401 && !originalRequest._retry) {
			if (!refreshToken) {
				// localStorage.removeItem('accessToken')
				// window.location.reload()
				return Promise.reject(error)
			}
			if (!isRefreshing) {
				isRefreshing = true
				localStorage.removeItem('accessToken')

				return axios
					.post('/v1/employee/refresh_token', {
						refresh_token: refreshToken,
					})
					.then((res) => {
						const accessToken = res.data.data.access_token

						localStorage.setItem('accessToken', accessToken)

						originalRequest.headers[
							'Authorization'
						] = `Bearer ${accessToken}`

						refreshSubscribers.forEach((callback) =>
							callback(accessToken)
						)
						refreshSubscribers = []

						return axios(originalRequest)
					})
					.catch((err) => {
						// console.log('Error: ', err);
						
						if (err.response && err.response.status === 401) {
							localStorage.removeItem('accessToken')
							localStorage.removeItem('refreshToken')
							window.location.reload()
						}
						return Promise.reject(err)
					})
					.finally(() => {
						isRefreshing = false
					})
			} else {
				return new Promise((resolve) => {
					refreshSubscribers.push((access_token) => {
						originalRequest.headers[
							'Authorization'
						] = `Bearer ${access_token}`
						resolve(axios(originalRequest))
					})
				})
			}
		}
		return Promise.reject(error)
	}
)

export default client
export * from 'axios'
