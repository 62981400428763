import React, {useEffect, useState} from 'react'
import {Button, Divider, Stack} from '@mui/material'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../components/Auth/auth'
import {useUnseenNotification} from '../components/UnseenNotificationProvider'
import {RedAlertCircle} from '../components/RedAlertCircle'

function Sidebar({isCanBo}: {isCanBo: boolean}) {
	const auth = useAuth()
	// const [currentDir, setCurrentDir] = useState('/task_manage')
	const navigate = useNavigate()
	const location = useLocation()

	const getCurrentDir = () => {
		return location.pathname
	}

	const handleLogout = (): void => {
		auth.logout()
		navigate('/login')
	}

	const handleClick = (itemPath: string): void => {
		// setCurrentDir(itemPath)
		navigate(itemPath)
	}

	return (
		<div className='h-screen w-[230px] flex flex-col fixed justify-between border-r-2'>
			<p className='font-bold text-lg px-2 py-2 border-b-[1px] text-blue-900'>
				MẶT TRẬN TỔ QUỐC QUẬN 5
			</p>
			<div className='h-screen w-[230px] flex flex-col justify-between px-2 pt-4'>
				<Stack className='flex flex-col  gap-2'>
					{auth.user.level !== 'Staff' && (
						<SidebarItem
							name='Quản lí giao việc'
							path='/task_assign'
							current={getCurrentDir()}
							handleClick={handleClick}
						/>
					)}
					{auth.user.level !== 'Leader' && (
						<SidebarItem
							name='Quản lí công việc'
							path='/task_manage'
							current={getCurrentDir()}
							handleClick={handleClick}
						/>
					)}
					{auth.user.level !== 'Staff' && (
						<SidebarItem
							name='Quản lí cán bộ'
							path='/employee_manage'
							current={getCurrentDir()}
							handleClick={handleClick}
						/>
					)}
					<SidebarNotificationItem
						name='Thông báo'
						path='/notification'
						current={getCurrentDir()}
						handleClick={handleClick}
					/>
					<SidebarItem
						name='Thông tin tài khoản'
						path='/profile'
						current={getCurrentDir()}
						handleClick={handleClick}
					/>
				</Stack>

				<Stack>
					<Divider />
					<Button
						variant='outlined'
						sx={{
							fontWeight: 900,
							fontSize: '1.15rem',
							padding: '8px 16px',
							marginBottom: '10px',
						}}
						onClick={handleLogout}>
						Đăng xuất
					</Button>
				</Stack>
			</div>
		</div>
	)
}

export default Sidebar

interface SidebarItemProps {
	name: string
	path: string
	current: string
	handleClick: (itemPath: string) => void
}

function SidebarItem({name, path, current, handleClick}: SidebarItemProps) {
	return (
		<Button
			variant='text'
			color='primary'
			sx={{
				height: '60px',
				fontWeight: 'bold',
				backgroundColor: `${current === path ? '#1976d2' : ''}`,
				color: `${current === path ? '#fff' : ''}`,
				'&:hover': {
					color: '#fff',
					backgroundColor: '#1976d2',
				},
			}}
			onClick={() => {
				handleClick(path)
			}}>
			{name}
		</Button>
	)
}

function SidebarNotificationItem({name, path, current, handleClick}: SidebarItemProps) {
	const {total_unseen_alert_number, total_unseen_notification_number} =
		useUnseenNotification()
	
	return (
		<Button
			variant='text'
			color='primary'
			sx={{
				position: 'relative',
				height: '60px',
				fontWeight: 'bold',
				backgroundColor: `${current === path ? '#1976d2' : ''}`,
				color: `${current === path ? '#fff' : ''}`,
				'&:hover': {
					color: '#fff',
					backgroundColor: '#1976d2',
				},
			}}
			onClick={() => {
				handleClick(path)
			}}>
			{name}
			<RedAlertCircle
				text={total_unseen_alert_number + total_unseen_notification_number}
				isAbsolute
			/>
		</Button>
	)
}
