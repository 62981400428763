import {Avatar} from '@mui/material'
import {AlertType, AlertTypeGroup} from '../../interfaces/notification'
import dateFormatter from '../../helpers/formatters/dateFormatter'
import {useState, useEffect} from 'react'
import DisplayNotificationModal from '../Modal/DisplayNotificationModal'
import clsx from 'clsx'
import { IFile } from '../../interfaces/notification'

interface NotificationItemProps {
	type: AlertType
	createdAt: Date
	taskName?: string
	assignees?: string[]
	reviewer?: string
	unit?: string
	title?: string
	content?: string
	attachments?: IFile[]
}

function NotificationItem({
	type,
	taskName,
	createdAt,
	assignees,
	reviewer,
	unit,
	title,
    content,
    attachments
}: NotificationItemProps) {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const getStatusGroup = () => {
		if (type === AlertType.success) {
			return (
				<>
					đã <span style={{color: '#04D700'}}>hoàn thành</span>
				</>
			)
		} else if (type === AlertType.pending) {
			return (
				<>
					đang <span style={{color: '#ffca28'}}>chờ duyệt</span> cho
				</>
			)
		} else if (type === AlertType.expired) {
			return (
				<>
					đã <span style={{color: 'red'}}>trễ hạn</span>
				</>
			)
		}
	}

	const getHead = () => {
		if (AlertTypeGroup.task.includes(type)) {
			return 'Công việc'
		} else if (AlertTypeGroup.notice.includes(type)) {
			return 'Nhắc nhở'
		} else if (AlertTypeGroup.custom.includes(type)) {
			return reviewer
		}

		return unit
	}

	const getDecisionGroup = () => {
		if (type === AlertType.approved) {
			return <>hoàn thành</>
		} else if (type === AlertType.refused) {
			return <>chưa hoàn thành</>
		}
	}
	const getTypeGroup = () => {
		if (AlertTypeGroup.status.includes(type)) {
			return (
				<>
					<b>{assignees?.join(', ')}</b> {getStatusGroup()} công việc{' '}
					<b>{taskName}</b>
				</>
			)
		} else if (AlertTypeGroup.decision.includes(type)) {
			return (
				<>
					<b>{reviewer}</b> đã duyệt {getDecisionGroup()} cho công việc{' '}
					<b>{taskName}</b>
				</>
			)
		} else if (AlertTypeGroup.task.includes(type)) {
			return (
				<>
					<b>{reviewer}</b> đã giao công việc <b>{taskName}</b> cho{' '}
					{/* {type === AlertType.assigned && 'bạn,'}{' '} */}
					{assignees?.join(', ')}
				</>
			)
		} else if (AlertTypeGroup.notice.includes(type)) {
			return (
				<>
					Công việc <b>{taskName}</b> còn{' '}
					{type === AlertType.oneDay ? '1' : '3'} ngày nữa là hết
					hạn, hãy mau hoàn thành
				</>
			)
		}
		else if (AlertTypeGroup.custom.includes(type)) {
			return (
                <>
                    {title}
                </>
            )
		}
	}

	const handleClick = () => {
		if (type === AlertType.receiving) {
			handleOpen()
		}
	}

	return (
		<>
			<div className={clsx('w-full flex flex-row bg-[#ECECEC] gap-4 px-4 py-4', {
				'cursor-pointer': type === AlertType.receiving
			})}
				onClick={handleClick}
			>
				<p className='flex-[1]'>{getHead()}</p>
				<p className='flex-[5]'>{getTypeGroup()}</p>
				<p>{dateFormatter(createdAt)['HH:MM dd/mm/yyyy']}</p>
			</div>
			<DisplayNotificationModal
				open={open}
				onClose={handleClose}
				title={title}
				sender={reviewer}
				receivers={assignees}
				createdAt={createdAt}
				content={content}
				attachments={attachments}
			/>
		</>
	)
}

export default NotificationItem
