import {useState, useEffect} from 'react'
import {
	Button,
	Modal,
	Typography,
	FormControl,
	InputLabel,
	Select,
	TextField,
	MenuItem,
	CircularProgress,
	Stack,
	Chip,
	SelectChangeEvent,
} from '@mui/material'
import TextEditor from '../../TextEditor'
import useLoading from '../../../hooks/useLoading'
import useNotificationForm from '../../../hooks/useNotificationForm'
import {WardInfo} from '../../../interfaces/ward'
import client from '../../../services/axios'
import {IPagination} from '../../../interfaces/pagination'
import {EmployeeInfo} from '../../../interfaces/employees'
import {formatBytes} from '../../../helpers/formatters/binaryUnitFormat'
import {toast} from 'react-toastify'
import {useUnseenNotification} from '../../UnseenNotificationProvider'

function SendNotificationModal() {
	const [open, setOpen] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)
	const wardsLoader = useLoading()
	const employeeLoader = useLoading()
	const [wards, setWards] = useState<WardInfo[]>([])
	const [employees, setEmployees] = useState<EmployeeInfo[]>([])
	const [employeeOpen, setEmployeeOpen] = useState(false)
	const notiForm = useNotificationForm()
	const {socket} = useUnseenNotification()

	const fetchingWards = async () => {
		try {
			wardsLoader.setIsLoading(true)
			const res = await client.get('/v1/ward')
			const data = res.data.data as IPagination<WardInfo>
			const wardsData = data.items
			setWards(wardsData)
			notiForm.setWardId(2)
			wardsLoader.setIsLoading(false)
		} catch (error) {
			wardsLoader.setIsLoading(false)
			wardsLoader.setErr(true)
		}
	}

	const fetchingEmployees = async () => {
		try {
			employeeLoader.setIsLoading(true)
			const res = await client.get('/v1/manager/available_employees', {
				params: {ward_id: notiForm.wardId, allEmployeeOfWard: 1},
			})
			const data = res.data.data as IPagination<EmployeeInfo>
			const employeesData = data.items
			setEmployees(employeesData)
			employeeLoader.setIsLoading(false)
		} catch (error) {
			employeeLoader.setIsLoading(false)
			employeeLoader.setErr(true)
		}
	}

	const handleEmployeeSelectOpen = () => {
		setEmployeeOpen(true)
		fetchingEmployees()
	}

	const handleEmployeeSelectChoose = (e: SelectChangeEvent<EmployeeInfo[]>) => {
		const id = Number(e.target.value)

		const employee = employees.find((value) => value.employee_id === id)

		if (employee) {
			notiForm.addReceiver(employee)
		}
	}

	const handleDeleteReceiver = (id: number) => {
		const employee = employees.find((value) => value.employee_id === id)
		if (employee) {
			notiForm.removeReceiver(employee)
		}
	}

	const handleAcceptButtonClick = async () => {
		try {
			if (
				notiForm.receivers.length === 0 ||
				notiForm.title === '' ||
				notiForm.content === ''
			) {
				toast.error('Yêu cầu điền đủ thông tin trước khi gửi')
				return
			}

			// console.log('notiForm: ', notiForm)

			const formData = new FormData()
			for (const file of notiForm.files) {
				formData.append('file', file)
			}
			formData.append('ward_id', notiForm.wardId.toString())
			for (const receiver of notiForm.receivers) {
				formData.append('employee_id_list', receiver.employee_id.toString())
			}
			formData.append('header', notiForm.title)
			formData.append('content', notiForm.content)

			// console.log(formData);

			const res = await toast.promise(
				client.post('/v1/manager/notification', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}),
				{
					pending: 'Thông báo báo đang được gửi',
					success: 'Thông báo đã được gửi',
					error: 'Có lỗi xảy ra, vui lòng thử lại sau',
				}
			)

			socket?.emit(
				'notification',
				notiForm.receivers.map((e) => e.employee_id),
				notiForm.wardId
			)
			handleClose()
			notiForm.reset()
		} catch (error) {
			// toast.error('Gửi thông báo thất bại')
		}
	}

	const handleCancelButtonClick = () => {
		handleClose()
		notiForm.reset()
	}

	const handleAttachFile = (files: FileList) => {
		console.log(files)

		for (let i = 0; i < files.length; i++) {
			const file = files[i]
			notiForm.addFile(file)
		}
	}

	const handleRemoveFile = (file: File) => {
		notiForm.removeFile(file)
	}

	useEffect(() => {
		fetchingWards()
	}, [])

	return (
		<>
			<Button variant='contained' color='primary' onClick={handleOpen}>
				Gửi thông báo
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<div className='min-w-[900px] max-h-screen absolute p-8 pt-2 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] bg-white flex flex-col items-center gap-4 overflow-auto'>
					<div className='flex flex-row'>
						<h2
							id='modal-modal-title'
							className='font-bold text-center text-3xl'>
							Gửi thông báo
						</h2>
					</div>

					<div className='w-full flex flex-col gap-3'>
						<div className='flex flex-col gap-3'>
							<h3 className='text-2xl font-bold'>
								Thông tin người nhận
							</h3>
							<FormControl sx={{minWidth: 100}}>
								<InputLabel id='seleted-ward-label'>
									Đơn vị *
								</InputLabel>
								<Select
									labelId='seleted-ward-label'
									id='seleted-ward'
									value={notiForm.wardId}
									onChange={(e) =>
										notiForm.setWardId(
											Number(e.target.value)
										)
									}
									label='Đơn vị *'
									required
									MenuProps={{
										PaperProps: {
											sx: {
												maxHeight: 200,
											},
										},
									}}>
									{wards.length > 0 &&
										wards.map((value) => (
											<MenuItem
												key={value.ward_id}
												value={value.ward_id}>
												{value.name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
							<FormControl sx={{minWidth: 100}}>
								<InputLabel id='selected-receivers-label'>
									Người nhận *
								</InputLabel>
								<Select
									labelId='selected-receivers-label'
									id='selected-receivers'
									value={notiForm.receivers}
									required
									disabled={false}
									onChange={handleEmployeeSelectChoose}
									label='Người nhận *'
									open={employeeOpen}
									onOpen={handleEmployeeSelectOpen}
									onClose={() => setEmployeeOpen(false)}
									MenuProps={{
										PaperProps: {
											sx: {
												maxHeight: 200,
											},
										},
									}}>
									{employeeLoader.isLoading ? (
										<div className='flex flex-col items-center p-1'>
											<CircularProgress />
										</div>
									) : employeeLoader.err ? (
										<Typography className='pl-2'>
											Có lỗi xảy ra
										</Typography>
									) : employees.length ? (
										employees.map((employee) => (
											<MenuItem
												value={employee.employee_id}
												key={employee.employee_id}>
												{employee.name} -{' '}
												{employee.phone}
											</MenuItem>
										))
									) : (
										<Typography className='pl-2'>
											Không tìm thấy kết quả nào
										</Typography>
									)}
								</Select>
							</FormControl>
							<Typography className='font-light pl-3'>
								Danh sách Người nhận:
							</Typography>
							<Stack
								sx={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
									gap: 1,
								}}>
								{notiForm.receivers.length > 0 &&
									notiForm.receivers.map((value) => (
										<Chip
											key={value.employee_id}
											onDelete={() =>
												handleDeleteReceiver(
													value.employee_id
												)
											}
											label={value.name}
											color='primary'
										/>
									))}
							</Stack>
						</div>
						<div className='flex flex-col gap-4'>
							<h3 className='text-2xl font-bold'>
								Nội dung thông báo
							</h3>
							<FormControl>
								<TextField
									label='Tiêu đề'
									value={notiForm.title}
									onChange={(e) =>
										notiForm.setTitle(e.target.value)
									}
								/>
							</FormControl>
							<div className='w-full flex flex-col gap-2'>
								{/* <label className='pl-3'>Nội dung</label> */}
								<TextEditor
									placeholder='Điền nội dung thông báo'
									value={notiForm.content}
									onChange={(value) =>
										notiForm.setContent(value)
									}
									onDropFiles={handleAttachFile}
								/>
							</div>
							<Typography className='font-light pl-3'>
								File đính kèm:
							</Typography>
							<Stack
								sx={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
									gap: 1,
								}}>
								{notiForm.files.length > 0 &&
									notiForm.files.map((value) => (
										<Chip
											key={
												value.name +
												value.lastModified
											}
											onDelete={() =>
												handleRemoveFile(value)
											}
											label={`${
												value.name
											} (${formatBytes(value.size)})`}
											color='primary'
										/>
									))}
							</Stack>
						</div>
						<div className='flex flex-row justify-end gap-2'>
							<Button
								variant='contained'
								color='error'
								onClick={handleCancelButtonClick}>
								Hủy
							</Button>
							<Button
								variant='contained'
								color='primary'
								onClick={handleAcceptButtonClick}>
								Xác nhận
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SendNotificationModal
